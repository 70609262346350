import React, { useRef, useState } from 'react';
import { ImageElement, ImageWrapper } from './styled';
import { BrokenFile } from '../BrokenFile';

type ImageContainerProps = {
  imageUrl: string;
  widthStretch?: boolean;
};

export const ImageContainer: React.FC<ImageContainerProps> = ({
  imageUrl,
  widthStretch,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const onLoad = () => {
    setIsLoading(false);
    setError(false);
  };
  const onError = () => {
    if (!isLoading) {
      setError(true);
    }
    setIsLoading(false);
  };

  return (
    <ImageWrapper
      style={{ position: 'relative' }}
      ref={containerRef}
      widthStretch={!!widthStretch}
      hasImage={!!imageUrl}
    >
      {imageUrl && (
        <ImageElement
          src={imageUrl}
          data-src={imageUrl}
          alt={imageUrl}
          loading="lazy"
          onLoad={onLoad}
          onError={onError}
        />
      )}
      <BrokenFile error={error} url={imageUrl} />
    </ImageWrapper>
  );
};
