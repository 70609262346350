import { useFormikContext } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Collapse } from '@mui/material';
import { useAuth } from '@clerk/clerk-react';
import {
  CardsGridWrapper,
  CardsGridDocsWrapper,
  SectionWrapper,
  TextWithLines,
  DuplicatedFilesText,
} from './styled';
import { FileCard } from './FileCard';
import { SectionAddContent } from './SectionAddContent';
import { SectionHeader } from './SectionHeader';
import { DocumentCard } from './DocumentCard';
import { SectionFooter } from './SectionFooter';
import { IdeaCard } from './IdeaCard';
import {
  isDocument,
  isImage,
  isVideo,
} from '../../../../../../../shared/utils/commonFunctions';
import { getSectionErrorsAndTouched } from '../../../../../../../shared/utils/createGallery';
import { ErrorMessage } from '../../../../../../../shared/components/styled';
import { getIndex } from '../../../../../../../shared/utils/createGallery/getIndex';
import { useUploadFiles } from '../../../../../hooks/useUploadFiles';
import {
  deleteFromErrorFiles,
  unsetWarning,
} from '../../../../../redux/gallery/uploadFiles/uploadFilesSlice';
import { FileRole, UploadingItem } from '../../../../../types/gallery';
import { deleteGalleryFile } from '../../../../../redux/gallery/singleGallery/singleGalleryCreatorThunk';
import { CustomAlert } from '../../../../../../../shared/components/CustomAlert/CustomAlert';
import { useCreatorAppDispatch } from '../../../../../../../shared/hooks/useTypedSelector';
import {
  CreatorGalleryForm,
  CreatorGallerySection,
} from '../../../../../../../shared/types/creatorGallery';
import { CreatorGalleryFile } from '../../../../../../../shared/types/commonGallery';

type SectionProps = {
  section: CreatorGallerySection;
};

const getDocsImgVideoElements = (
  files: CreatorGalleryFile[],
  uploadingFiles: UploadingItem[],
) => {
  const uploadingImgsVideos: UploadingItem[] = [];
  const uploadingDocs: UploadingItem[] = [];
  if (uploadingFiles.length) {
    uploadingFiles.forEach((item) => {
      if (isImage(item) || isVideo(item)) {
        uploadingImgsVideos.push(item);
      } else {
        uploadingDocs.push(item);
      }
    });
  }

  const imgsVideos = files?.filter(
    (item) => (isImage(item) || isVideo(item)) && item.url,
  );
  const docs = files?.filter((item) => isDocument(item));

  return {
    imgsVideos,
    docs,
    uploadingImgsVideos,
    uploadingDocs,
  };
};

export const Section: React.FC<SectionProps> = ({ section }) => {
  const { t } = useTranslation('createNewGallery');
  const {
    setFieldValue, values, errors, touched,
  } = useFormikContext<CreatorGalleryForm>();
  const indexSection = getIndex(values.sections, section?.id);
  const {
    uploading,
    onUpload,
    stateFiles,
    error,
    errorFiles,
    files,
    setFiles,
    warning,
    isDuplicate,
  } = useUploadFiles(section.id);
  const dispatch = useCreatorAppDispatch();
  const { getToken } = useAuth();

  const sendFilesAgain = (fileName: string) => {
    const file = files.find((item) => fileName === item.name);
    if (file) {
      onUpload({
        files: [file],
        galleryId: values.id,
        fileRole: FileRole.Regular,
        sectionId: section.id,
        sectionName: section.title,
      });
    }
  };

  const [isExpanded, setIsExpanded] = useState(false);

  const handleDeleteFile = (id: string) => {
    dispatch(
      deleteGalleryFile({
        galleryId: values.id,
        sectionId: values.sections[indexSection].id,
        fileId: id,
        getToken,
      }),
    );
    const file = section.files.find((item) => item.id !== id);
    const filteredFiles = files.filter((item) => item.name !== file?.name);
    setFiles(filteredFiles);

    setFieldValue(
      `sections[${indexSection}].files`,
      section.files.filter((item) => item.id !== id),
    );
  };
  const handleDeleteErrorFile = (name: string) => {
    dispatch(
      deleteFromErrorFiles({
        files: [{ name, type: '' }],
        sectionId: section.id,
      }),
    );
    const filteredFiles = files.filter((item) => item.name !== name);
    setFiles(filteredFiles);
  };
  const handleDeleteIdea = (id: string) => {
    setFieldValue(
      `sections[${indexSection}].ideas`,
      section.ideas.filter((item) => item.id !== id),
    );
  };

  const {
    imgsVideos,
    docs,
    uploadingImgsVideos,
    uploadingDocs,
    // eslint-disable-next-line max-len
  } = useMemo(
    () => getDocsImgVideoElements(section?.files, uploading),
    [section?.files, uploading],
  );

  const arrIdeas = useMemo(
    () => section?.ideas?.map((item, index) => (
      <IdeaCard
        key={item.name}
        handleDeleteIdea={handleDeleteIdea}
        indexSection={indexSection}
        indexIdea={index}
        idea={item}
      />
    )),
    [section?.ideas],
  );

  const sectionErrorsAndTouched = useMemo(
    () => getSectionErrorsAndTouched(errors, touched, indexSection),
    [errors, touched, indexSection],
  );
  // eslint-disable-next-line max-len
  const filesError = sectionErrorsAndTouched?.error?.files
    && sectionErrorsAndTouched?.wasTouched?.files
    ? (sectionErrorsAndTouched?.error?.files as string)
    : null;

  useEffect(() => {
    if (stateFiles.length) {
      // eslint-disable-next-line max-len
      const arr = stateFiles.filter(
        (item: any) => !section.files.find((item2) => item.name === item2.name),
      );
      setFieldValue(`sections[${indexSection}].files`, [
        ...section.files,
        ...arr,
      ]);
    }
  }, [stateFiles]);

  return (
    <>
      <SectionWrapper>
        <SectionHeader
          section={section}
          uploading={uploading}
          errorFiles={errorFiles}
          allFiles={files}
          stateFiles={stateFiles}
        />
        <DuplicatedFilesText>
          {isDuplicate ? 'Duplicate files were removed' : ''}
        </DuplicatedFilesText>
        <Collapse in={isExpanded} collapsedSize={201}>
          <CardsGridWrapper>
            <SectionAddContent
              onUpload={onUpload}
              section={section}
              indexSection={indexSection}
              gallery={values.id}
            />
            {!!errorFiles.length
              && errorFiles.map((item) => (
                <FileCard
                  handleTryAgain={sendFilesAgain}
                  key={item.name}
                  handleDeleteFile={handleDeleteErrorFile}
                  file={{
                    url: '',
                    name: item.name,
                    id: item.name,
                    type: item.type,
                  }}
                  id={item.name}
                  error
                  errorMessage={item.errorMessage}
                />
              ))}
            {uploadingImgsVideos.map((item) => (
              <FileCard
                // isLoadingWithProgress
                isLoading={!!uploading.length}
                handleTryAgain={sendFilesAgain}
                key={item.name}
                handleDeleteFile={handleDeleteFile}
                file={{
                  url: '',
                  name: item.name,
                  id: item.name,
                  type: item.type,
                }}
                id={item.name}
                error={
                  !!errorFiles.find((errorItem) => errorItem.name === item.name)
                }
              />
            ))}
            {imgsVideos.map((item) => (
              <FileCard
                handleTryAgain={sendFilesAgain}
                key={item.id}
                handleDeleteFile={handleDeleteFile}
                file={item}
                id={item.id}
              />
            ))}
            {arrIdeas}
          </CardsGridWrapper>
          {[...uploadingDocs, ...docs].length ? (
            <>
              <TextWithLines>{t('secondStep.otherFiles')}</TextWithLines>
              <CardsGridDocsWrapper>
                {uploadingDocs?.map((item) => (
                  <DocumentCard
                    handleTryAgain={sendFilesAgain}
                    file={{
                      url: '',
                      name: item.name,
                      id: item.name,
                      type: item.type,
                    }}
                    handleDelete={handleDeleteFile}
                    key={item.name}
                  />
                ))}
                {docs?.map((item) => (
                  <DocumentCard
                    handleTryAgain={sendFilesAgain}
                    file={item}
                    handleDelete={handleDeleteFile}
                    key={item.id}
                  />
                ))}
              </CardsGridDocsWrapper>
            </>
          ) : null}
        </Collapse>
        <ErrorMessage>
          {filesError}
          {error || null}
        </ErrorMessage>
        <SectionFooter
          showViewMore={
            !!(
              // eslint-disable-next-line no-unsafe-optional-chaining
              (
                [
                  ...(section?.files || []),
                  ...(section?.ideas || []),
                  ...uploading,
                  ...errorFiles,
                ].length > 2 || [...docs, ...uploadingDocs].length
              )
            )
          }
          isExpanded={isExpanded}
          setIsExpanded={setIsExpanded}
          indexSection={indexSection}
        />
      </SectionWrapper>
      <CustomAlert
        type="warning"
        message={warning}
        title="The file wasn't uploaded"
        isOpenWindow={!!warning}
        onClose={() => dispatch(unsetWarning())}
      />
    </>
  );
};
