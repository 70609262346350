import { Collapse, Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  MediumBlackText,
  SimpleBlackText,
} from '../../../../../../../shared/styles';
import { SettingsGalleryCard } from './SettingsGalleryCard';
import { RowCards, StorageLoaderWrapper } from './styled';
import { useTypedSelectorCreator } from '../../../../../../../shared/hooks/useTypedSelector';
import { PinkLoader } from '../../../../../../../shared/components/Loaders/PinkLoader/PinkLoader';
import { convertFileSize } from '../../../../../utils/helpers';
import { ArrowSimpleIcon } from '../../../../../../../shared/icons';
import { ViewMoreButton } from '../../NewGalleryCreator/NewGalleryCreatorSecondStep/styled';

export const Storage: React.FC = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { t } = useTranslation('settings');
  const currentUser = useTypedSelectorCreator((state) => state.creator.currentUser);

  const isLoadingData = useTypedSelectorCreator((state) => state.galleries.loadingGet);
  const galleries = useTypedSelectorCreator((state) => state.galleries.galleries);

  const usedStorage = currentUser?.totalStorageUsed || 0;
  const totalStorage = currentUser?.currentSubscription?.totalStorage || 0;

  return (
    <>
      <SimpleBlackText>{t('storage')}</SimpleBlackText>
      <Grid
        container
        item
        sm={12}
        style={{ margin: '30px 0px', height: 'fit-content', minHeight: '0' }}
      >
        <Grid
          container
          item
          xl={5}
          lg={6}
          md={9}
          direction="row"
          style={{ height: 'fit-content' }}
        >
          <MediumBlackText>{t('availablePlan')}</MediumBlackText>
          <MediumBlackText fontWeight={500} style={{ marginLeft: '10px' }}>
            <Trans t={t} i18nKey="storageGB">
              {{ storageGB: convertFileSize(totalStorage, 0) }}
            </Trans>
          </MediumBlackText>
        </Grid>
        <Grid
          container
          item
          lg={5}
          md={9}
          sm={5}
          direction="row"
          style={{ height: 'fit-content' }}
        >
          <MediumBlackText>{t('storageUsed')}</MediumBlackText>
          <MediumBlackText fontWeight={500} style={{ marginLeft: '10px' }}>
            <Trans t={t} i18nKey="storageMB">
              {{ storageMB: convertFileSize(usedStorage) }}
            </Trans>
          </MediumBlackText>
        </Grid>
      </Grid>
      {isLoadingData && (
      <StorageLoaderWrapper>
        <PinkLoader />
      </StorageLoaderWrapper>
      )}
      <Collapse in={isExpanded} collapsedSize={180}>
        <RowCards>
          {galleries?.map((item) => (
            <SettingsGalleryCard
              galleryId={item.id}
              key={item.id}
              coverFile={item?.coverFile?.desktop || item?.coverFile?.original}
              name={item.name}
              size={convertFileSize(item?.totalSize)}
            />
          ))}
        </RowCards>
      </Collapse>
      <div style={{ maxWidth: '650px' }}>
        {galleries?.length > 3 && (
          <ViewMoreButton type="button" onClick={() => setIsExpanded((prev: boolean) => !prev)}>
            <span>View more</span>
            <ArrowSimpleIcon direction={isExpanded ? 'up' : 'down'} />
          </ViewMoreButton>
        )}
      </div>
    </>
  );
};
