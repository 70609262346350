/* eslint-disable no-unsafe-optional-chaining */
import { v4 as uuidv4 } from 'uuid';
import { dateRegExp } from '../validationValues/regExp';
import {
  CreatorGalleryForm,
  CreatorGalleryGet,
  CreatorGallerySection,
} from '../../shared/types/creatorGallery';
import { CreatorGalleryFile, GalleryFileSettings } from '../../shared/types/commonGallery';

const section: CreatorGallerySection = {
  id: uuidv4(),
  title: 'Section 1',
  files: [],
  ideas: [],
  hasFreeFiles: false,
  freeFileLimit: 0,
  freeFilesRemaining: 0,
};

export const galleryInitialValues = (
  id: string,
  businessName?: string,
  currentGallery?: CreatorGalleryGet,
): CreatorGalleryForm => ({
  creatorBusinessName:
    currentGallery?.creatorBusinessName || businessName || '',
  step: 1,
  id,
  name: currentGallery?.name || '',
  coverFile: {
    original: {
      name: 'cover',
      url: currentGallery?.coverFile?.original?.url ?? '',
      id: currentGallery?.coverFile?.original?.id ?? '',
      type: 'image/jpeg',
    },
    mobile: currentGallery?.coverFile?.mobile
      ? {
        name: '',
        url: currentGallery?.coverFile?.mobile?.url ?? '',
        id: currentGallery?.coverFile?.mobile?.id ?? '',
        type: 'image/jpeg',
      }
      : null,
    tablet: currentGallery?.coverFile?.tablet
      ? {
        name: '',
        url: currentGallery?.coverFile?.tablet?.url ?? '',
        id: currentGallery?.coverFile?.tablet?.id ?? '',
        type: 'image/jpeg',
      }
      : null,
    desktop: currentGallery?.coverFile?.desktop
      ? {
        name: '',
        url: currentGallery?.coverFile?.desktop?.url ?? '',
        id: currentGallery?.coverFile?.desktop?.id ?? '',
        type: 'image/jpeg',
      }
      : null,
  },
  date: currentGallery?.date?.match(dateRegExp)
    ? new Date(currentGallery?.date)
    : null,
  message: currentGallery?.message || '',
  galleryUrl: currentGallery?.galleryUrl || '',
  galleryDesign: +(currentGallery?.galleryDesign || 1),
  sections: currentGallery?.sections?.length
    ? [...currentGallery?.sections]
    : [section],
  hasRegistrationEmail: true, // this switch was hidden for now
  whitelistEmails: currentGallery?.whitelistEmails?.length
    ? currentGallery?.whitelistEmails
    : [],
  password: currentGallery?.password || '',
  sharing: !!currentGallery?.sharing || false, // this switch was hidden for now
  assist: !!currentGallery?.assist,
  autoAlert: !!currentGallery?.autoAlert,
  branding: !!currentGallery?.branding,
  testimonialCall: !!currentGallery?.testimonialCall,
  status: currentGallery?.status || 'draft',
  // new fields
  hasWhitelistEmails: !!currentGallery?.hasWhitelistEmails,
  hasReminder: !!currentGallery?.branding,
  whenRemind: currentGallery?.whenRemind,
  remindEmails: currentGallery?.remindEmails,
  whitelistRemindEmails: currentGallery?.whitelistRemindEmails,
  remindAllRegistered: !!currentGallery?.remindAllRegistered,
  removeBranding: !!currentGallery?.removeBranding,
  emailText:
    currentGallery?.emailText
    || 'Hey! This is an automated email to let you know your gallery is about to expire so if you have items you still need to download or there is extra content you wanted, now’s your chance! If you need more time, let us know and we can extend the activation of your gallery. Click below to view the gallery and please reach out if you have any questions.',
});

export const galleryFileInfo: GalleryFileSettings = {
  description: '',
  price: '',
  instantlyDownloadable: false,
  watermark: false,
  allowCommenting: false,
  fullWidth: false,
  videoThumbnailFile: undefined,
  hasVideoThumbnail: false,
};

export const getGalleryFileSettings = (
  currentSection: CreatorGallerySection,
  selectedFilesIds: string[],
): GalleryFileSettings => {
  if (selectedFilesIds.length) {
    const allFiles = [...currentSection.files, ...currentSection.ideas];
    const firstSelectedFileId = selectedFilesIds[0];
    const firstSelectedFile = allFiles.find(
      (item) => item.id === firstSelectedFileId,
    );

    const result: GalleryFileSettings = {
      description: firstSelectedFile?.description || '',
      price: firstSelectedFile?.price || '',
      instantlyDownloadable: !!(
        firstSelectedFile as CreatorGalleryFile
      )?.instantlyDownloadable,
      watermark: !!firstSelectedFile?.watermark,
      allowCommenting: !!firstSelectedFile?.allowCommenting,
      fullWidth: !!firstSelectedFile?.fullWidth,
      videoThumbnailFile: (
        firstSelectedFile as CreatorGalleryFile
      )?.videoThumbnailFile,
      hasVideoThumbnail: !!(
        firstSelectedFile as CreatorGalleryFile
      )?.hasVideoThumbnail,
    };

    return result;
  }
  return galleryFileInfo;
};
