import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TableBody, TableContainer, TableHead, TableRow,
} from '@mui/material';
import { useAuth } from '@clerk/clerk-react';
import { useTypedSelectorCreator, useCreatorAppDispatch } from '../../../../../../../shared/hooks/useTypedSelector';
import { fetchGetOrdersByCreatorId, fetchGetOrdersByGalleryId } from '../../../../../redux/orders/ordersThunk';
import { OrderTableRow } from './OrderTableRow';
import { Order } from '../../../../../types/sales';
import {
  OrdersTableCard, OrdersTableLoaderWrapper, OrdersTablePaginationWrapper, TitleTextOrdersTable,
} from '../styled';
import { StyledTable, StyledTableCell, StyledTablePagination } from '../../../../../../../shared/components/StyledTable/StyledTable';
import { PinkLoader } from '../../../../../../../shared/components/Loaders/PinkLoader/PinkLoader';
import { usePagination } from '../../../../../../../shared/hooks/usePagination';
import { ErrorMessage } from '../../../../../../../shared/components/styled';
import { SimpleBlackText } from '../../../../../../../shared/styles';

export const OrdersTable: React.FC = () => {
  const { t } = useTranslation('gallery');
  const {
    handleChangePage, handleChangeRowsPerPage, page, rowsPerPage,
  } = usePagination();
  const { ordersByCreatorId: orders, total } = useTypedSelectorCreator((state) => state.orders);
  const isLoading = useTypedSelectorCreator((state) => state.orders.loading);
  const error = useTypedSelectorCreator((state) => state.orders.error);
  const { getToken } = useAuth();

  const dispatch = useCreatorAppDispatch();

  useEffect(() => {
    dispatch(fetchGetOrdersByCreatorId({ getToken, limit: rowsPerPage, page: page + 1 }));
  }, [page, rowsPerPage]);

  // const rows: { order: Order, galleryName: string }[] = [];
  // if (orders) {
  //   Object.entries(orders).forEach((item) => {
  //     item[1].orders.forEach((order) => {
  //       rows.push({
  //         order,
  //         galleryName: item[1].name,
  //       });
  //     });
  //   });
  // }

  return (
    <OrdersTableCard anyOrders={!!total}>
      <TableContainer>
        <TitleTextOrdersTable fontWeight={600}>Your orders</TitleTextOrdersTable>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <StyledTable sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <StyledTableCell align="left" sx={{ whiteSpace: 'pre', paddingLeft: '0px' }}>Gallery name</StyledTableCell>
              <StyledTableCell align="left">{t('ordersTab.clientEmail')}</StyledTableCell>
              <StyledTableCell align="left">{t('ordersTab.date')}</StyledTableCell>
              <StyledTableCell align="left">{t('ordersTab.time')}</StyledTableCell>
              <StyledTableCell align="left">{t('ordersTab.status')}</StyledTableCell>
              <StyledTableCell sx={{ whiteSpace: 'pre' }} align="left">{t('ordersTab.cartTotal')}</StyledTableCell>
              <StyledTableCell sx={{ paddingRight: '0px' }} align="center">Details</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!isLoading && (
              orders?.map((item) => (
                <OrderTableRow
                  key={item.id}
                  row={item}
                  galleryName={item.name || 'No name'}
                />
              ))
            )}
          </TableBody>
        </StyledTable>
        {!total && !isLoading && (
          <OrdersTableLoaderWrapper>
            <SimpleBlackText>
              No orders
            </SimpleBlackText>
          </OrdersTableLoaderWrapper>
        )}
        {isLoading && (
        <OrdersTableLoaderWrapper>
          <PinkLoader size="xs" />
        </OrdersTableLoaderWrapper>
        )}
      </TableContainer>
      <OrdersTablePaginationWrapper>
        <StyledTablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={total}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPage={rowsPerPage}
        />
      </OrdersTablePaginationWrapper>
    </OrdersTableCard>
  );
};
