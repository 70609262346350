/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/ban-types */
import {
  styled,
  Table,
  TableCell,
  tableCellClasses,
  TablePagination,
  TablePaginationBaseProps,
  TablePaginationProps,
  TablePaginationTypeMap,
} from '@mui/material';
import * as variables from '../../../constants/styles';

export const StyledTable = styled(Table)(({ theme }) => ({
  root: {
    fontFamily: variables.mainFont,
  },
  list: {
    overflowY: 'auto',
    margin: 0,
    padding: 0,
    listStyle: 'none',
    height: '100%',
    '&::-webkit-scrollbar': {
      width: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: variables.textGrey,
      borderRadius: '163px',
      backgroundClip: 'padding-box',
    },
    '&::-webkit-scrollbar-track': {
      background: '#dbdbdb',
      borderRadius: '163px',
    },
  },
}));

export const tableContainerStyle = {
  '&::-webkit-scrollbar': {
    width: '4px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'grey',
    borderRadius: '163px',
    backgroundClip: 'padding-box',
  },
  '&::-webkit-scrollbar-track': {
    background: '#dbdbdb',
    borderRadius: '163px',
  },
};

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontFamily: variables.mainFont,
    backgroundColor: variables.colorGreyLight,
  },
  [`&.${tableCellClasses.body}`]: {
    fontFamily: variables.mainFont,
    borderBottom: '0px',
  },
}));

export const StyledTablePagination = styled(TablePagination)<TablePaginationProps & {component: React.ElementType}>(() => ({
  '& .MuiTablePagination-root': {
    backgroundColor: variables.colorGreyLight,
    background: variables.colorGreyLight,
  },
  '& .MuiPaper-root': {
    backgroundColor: variables.colorGreyLight,
    background: variables.colorGreyLight,
  },
  '& ul': {
    backgroundColor: variables.colorGreyLight,
    background: variables.colorGreyLight,
  },
  '& div': {
    backgroundColor: variables.colorGreyLight,
    background: variables.colorGreyLight,
  },
  '& .MuiTablePagination-select': {
    backgroundColor: variables.colorGreyLight,
    background: variables.colorGreyLight,
  },
  '&.MuiList-root': {
    backgroundColor: variables.colorGreyLight,
    background: variables.colorGreyLight,
  },
  select: {
    backgroundColor: variables.colorError,
  },
  menuItem: {
    backgroundColor: variables.colorGreyLight,
    background: variables.colorGreyLight,
  },
  '& .MuiTablePagination-menuItem': {
    backgroundColor: `${variables.colorGreyLight} !important`,
    background: `${variables.colorError} !important`,
  },
  '& option': {
    backgroundColor: `${variables.colorGreyLight} !important`,
    background: `${variables.colorGreyLight} !important`,
  },
}));
