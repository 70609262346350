import { combineReducers } from '@reduxjs/toolkit';
import notificationsSlice from './notifications/notificationsSlice';
import statisticsSlice from './sales/statistics/statisticsSlice';
import supportSlice from './support/supportSlice';
import gallerySalesSlice from './sales/sales/salesSlice';
import signUpCreatorSlice from './userCreator/signUp/signUpCreatorSlice';
import signInCreatorSlice from './userCreator/signIn/signInCreatorSlice';
import resetPasswordCreatorSlice from './userCreator/resetPassword/resetPasswordCreatorSlice';
import creatorSlice from './userCreator/creator/creatorSlice';
import tryAgainUploadFilesSlice from './gallery/uploadFiles/tryAgainUploadFilesSlice';
import swiperSlice from '../../GalleryView/redux/gallery/swiperSlice';
import creatorCheckPasswordSlice from './userCreator/creator/creatorCheckPasswordSlice';
import commentsCreatorSlice from './gallery/comments/commentsCreatorSlice';
import galleryCreatorSlice from './gallery/allGaleries/galleryCreatorSlice';
import uploadFilesSlice from './gallery/uploadFiles/uploadFilesSlice';
import interactionsCreatorSlice from './gallery/interactions/interactionsCreatorSlice';
import allSalesSlice from './sales/sales/allSalesSlice';
import fileSettingsCreatorSlice from './gallery/fileSettings/fileSettingsCreatorSlice';
import billingInfoSlice from './invoices/billingInfoSlice';
import subscriptionSlice from './userCreator/subscriptionSlice';
import plansSlice from './userCreator/plansSlice';
import ordersSlice from './orders/ordersSlice';
import singleGalleryCreatorSlice from './gallery/singleGallery/singleGalleryCreatorSlice';

export const rootReducer = combineReducers({
  tryAgainUploadFiles: tryAgainUploadFilesSlice,
  notifications: notificationsSlice,
  statistics: statisticsSlice,
  support: supportSlice,
  sales: gallerySalesSlice,
  allSales: allSalesSlice,
  signUpCreator: signUpCreatorSlice,
  signInCreator: signInCreatorSlice,
  resetPassword: resetPasswordCreatorSlice,
  creator: creatorSlice,
  swiper: swiperSlice,
  creatorCheckPassword: creatorCheckPasswordSlice,
  comments: commentsCreatorSlice,
  galleries: galleryCreatorSlice,
  gallery: singleGalleryCreatorSlice,
  uploadFiles: uploadFilesSlice,
  interactions: interactionsCreatorSlice,
  fileSettingsCreator: fileSettingsCreatorSlice,
  billingInfo: billingInfoSlice,
  subscription: subscriptionSlice,
  plans: plansSlice,
  orders: ordersSlice,
});
