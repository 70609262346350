import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth } from '@clerk/clerk-react';
import * as urls from '../../../../../../../../constants/urls';
import { useDialog } from '../../../../../../../../shared/hooks/useDialog';
import {
  CloseIcon,
  EditIcon,
  DeleteIcon,
} from '../../../../../../../../shared/icons';
import { TitleBlack } from '../../../../../../../../shared/styles';
import {
  DashboardHeaderWrapper,
  CloseButton,
  DashboardHeaderButtons,
  DashboardHeaderButton,
  DashboardHeaderBtnWrapper,
} from './styled';
import { CopyIconButton } from '../../../../../../../../shared/components/CopyIconButton/CopyIconButton';
import { SimpleTooltip } from '../../../../../../../../shared/components/HelpTip/SimpleTooltip';
import { fetchDeleteCreatorGallery } from '../../../../../../redux/gallery/allGaleries/galleryCreatorThunk';
import { useCreatorAppDispatch, useTypedSelectorCreator } from '../../../../../../../../shared/hooks/useTypedSelector';
import { PinkLoader } from '../../../../../../../../shared/components/Loaders/PinkLoader/PinkLoader';
import { ErrorMessage } from '../../../../../../../../shared/components/styled';

type DashboardHeaderProps = {
  title: string;
  idGallery: string;
  handleCloseDashboard: () => void;
  galleryLink: string;
};

export const DashboardHeader: React.FC<DashboardHeaderProps> = ({
  title,
  idGallery,
  handleCloseDashboard,
  galleryLink,
}) => {
  const { t } = useTranslation('gallery');
  const navigate = useNavigate();
  const dispatch = useCreatorAppDispatch();
  const creator = useTypedSelectorCreator((state) => state.creator?.currentUser);
  const { getToken } = useAuth();
  const deleteLoading = useTypedSelectorCreator(
    (state) => state.galleries.loadingDeleteId,
  );
  const errorDelete = useTypedSelectorCreator(
    (state) => state.galleries.errorDeleteItem,
  );

  const handleDelete = (id: string) => {
    dispatch(fetchDeleteCreatorGallery({ id, getToken }));
  };

  const { setIsOpen: setIsOpenDialogWindow, dialogWindow } = useDialog<string>(
    'confirmation',
    idGallery,
    handleDelete,
    t('deleteGallery.question'),
    t('deleteGallery.title'),
  );

  const handleEdit = () => {
    navigate(`${urls.newGalleryUrl}/${idGallery}`, { state: { status: 'edited' } });
  };

  return (
    <>
      <DashboardHeaderWrapper>
        <CloseButton type="button" onClick={handleCloseDashboard}>
          <CloseIcon />
        </CloseButton>
        <TitleBlack>{title}</TitleBlack>
        {errorDelete?.id === idGallery && errorDelete.message && (
        <div style={{ position: 'absolute', right: '100px', maxWidth: '350px' }}>
          <ErrorMessage>{errorDelete.message}</ErrorMessage>
        </div>
        )}
        <DashboardHeaderButtons>
          <CopyIconButton value={galleryLink} isLinkFull />
          <DashboardHeaderButton type="button" onClick={handleEdit} disabled={!creator?.onboarded}>
            <EditIcon />
            <SimpleTooltip relativeWidth={24} text={creator?.onboarded ? t('edit') : 'Complete Stripe registration'} />
          </DashboardHeaderButton>
          <DashboardHeaderBtnWrapper>
            <DashboardHeaderButton
              type="button"
              onClick={() => setIsOpenDialogWindow(true)}
              disabled={deleteLoading === idGallery}
            >
              {deleteLoading === idGallery && !!deleteLoading ? (
                <PinkLoader size="xs" />
              ) : (
                <DeleteIcon />
              )}
            </DashboardHeaderButton>
            <SimpleTooltip relativeWidth={24} text={t('galleryDelete')} />
          </DashboardHeaderBtnWrapper>
        </DashboardHeaderButtons>
      </DashboardHeaderWrapper>
      {dialogWindow}
    </>
  );
};
