import React from 'react';
import { FileAndCommentsWindow } from './FileAndCommentsWindow';
import { GalleryComment, GalleryFileBaseWithUrls } from '../../../../shared/types/commonGallery';

type FileAndCommentsWindowContainerProps = {
  file?: GalleryFileBaseWithUrls | null;
  isOpen: boolean;
  handleClose: () => void;
  comments: GalleryComment[];
  dateFormat: string;
  addComment: (comment: string, resetForm: () => void) => void;
  deleteComment: (commentId: string) => void;
}

export const FileAndCommentsWindowContainer: React.FC<FileAndCommentsWindowContainerProps> = ({
  file,
  isOpen,
  handleClose,
  comments,
  dateFormat,
  addComment,
  deleteComment,
}) => {
  if (file) {
    return (
      <FileAndCommentsWindow
        file={file}
        comments={comments}
        isOpen={isOpen}
        handleClose={handleClose}
        dateFormat={dateFormat}
        addComment={addComment}
        deleteComment={deleteComment}
      />
    );
  }
  return null;
};
