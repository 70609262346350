import { Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import { SignedIn, SignedOut, useAuth } from '@clerk/clerk-react';
import { GridFullHeight, GridItemFullHeight } from '../../../shared/components/GlobalContainer/styled';
import { HeaderWithLogo } from '../../../shared/components/HeaderWithLogo/HeaderWithLogo';
import { CreatorSignedInRoutes, CreatorSignedOutRoutes } from '../routers/CreatorRoutes';
import { Sidebar } from './CreatorAccount/Sidebar/Sidebar';
import { CreatorWalkThroughCards } from './CreatorAccount/CreatorWalkThroughCards/CreatorWalkThroughCards';
import { BetaCheckoutWindow } from '../../PasswordProtectedPage/containers/BetaCheckout';
import { useCreatorAppDispatch, useTypedSelectorCreator } from '../../../shared/hooks/useTypedSelector';
import { fetchGetCreatorGalleries } from '../redux/gallery/allGaleries/galleryCreatorThunk';

export const CreatorSignedIn: React.FC = () => {
  const dispatch = useCreatorAppDispatch();
  const { getToken } = useAuth();
  const currentUser = useTypedSelectorCreator((state) => state.creator.currentUser);

  useEffect(() => {
    dispatch(fetchGetCreatorGalleries(getToken));
  }, []);

  return (
    <>
      <GridFullHeight container spacing={4} justifyContent="space-between">
        <GridItemFullHeight item sm={3} xs={3}>
          <Sidebar />
        </GridItemFullHeight>
        <Grid item xl={8} md={8} style={{ paddingLeft: '32px' }}>
          <CreatorSignedInRoutes />
        </Grid>
      </GridFullHeight>
      {/* {currentUser && <NewsPopupWindow id={currentUser?.id} />} */}
      {(!currentUser?.currentSubscription?.priceId) && <BetaCheckoutWindow />}
      <CreatorWalkThroughCards />
    </>
  );
};

export const Creator: React.FC = () => (
  <>
    <SignedOut>
      <HeaderWithLogo isCreator={false} />
      <CreatorSignedOutRoutes />
    </SignedOut>
    <SignedIn>
      <CreatorSignedIn />
    </SignedIn>
  </>
);
