/* eslint-disable import/no-extraneous-dependencies */
import { Parser } from '@json2csv/plainjs';
import { saveAs } from 'file-saver';
import mime from 'mime';
import { OrderedFile } from '../types/sales';

export const makeCsvFile: (content: string) => Blob = (content) => {
  const result = `\ufeff${content}`;
  const fExt = 'csv';
  const mimeType = mime.getType(fExt);
  return new Blob([result], {
    type: `${mimeType};charset=utf-8`,
  });
};

export const downloadAs: (
    content: string,
    fileName?: string,
  ) => void = (content, fileName = 'Summary') => {
    const fName = fileName;
    const fExt = 'csv';
    saveAs(makeCsvFile(content), `${fName}.${fExt}`);
  };

const transformFilesData = (filesData: OrderedFile[]) => {
  const res = filesData.map((item) => ({
    'Gallery section': item.sectionName,
    'File name': item.fileName,
    'Download status': item.downloaded ? 'Downloaded' : 'Not downloaded',
    'Disable downloads': item.disableDownloads ? 'ON' : 'OFF',
    'Within free file limit': item.withinFreeFileLimit ? 'YES' : 'NO',
    'Instantly downloadable': item.instantlyDownloadable ? 'YES' : 'NO',
    Price: `$${item.price / 100}`,
  }));
  return res;
};

export const getCSV = (filesData: OrderedFile[]) => {
  try {
    const parser = new Parser();
    const csv = parser.parse(transformFilesData(filesData));
    return csv;
  } catch (err: any) {
    console.error(err);
    return err.message;
  }
};
