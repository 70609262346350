import { months } from '../../../../../../../constants';
import { getPrice } from '../../../../../../../shared/utils';

// temporary data for charts

export const weekDayNames = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

export const getDataMonth = (salesPerMonth: number[]): {
  name: string,
  amount: number,
}[] => {
  const arr = months?.map((item, i) => ({
    name: item,
    amount: getPrice(salesPerMonth[i]) || 0,
  }));
  return arr;
};

export const getDataWeek = (salesPerWeekDay: number[]): {
  name: string,
  amount: number,
}[] => {
  if (!salesPerWeekDay.length) {
    return [];
  }
  const arr = weekDayNames?.map((item, i) => ({
    name: item,
    amount: getPrice(salesPerWeekDay[i]) || 0,
  }));
  return arr;
};

export const getDataDay = (salesPerDay: number[]): {
  name: number,
  amount: number,
}[] => {
  const arr: {
    name: number,
    amount: number,
  }[] = [];

  salesPerDay?.forEach((item, i) => {
    arr.push({
      name: i + 1,
      amount: getPrice(item),
    });
  });
  return arr;
};
