import React, { ChangeEvent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { Grid } from '@mui/material';
import { CommonInput } from '../../../../../../../shared/components/FormElements/CommonInput/CommonInput';
import { getSectionErrorsAndTouched } from '../../../../../../../shared/utils/createGallery/getErrorsAndTouched';
import {
  CheckboxTextWrapper,
} from './styled';
import { HelpTip } from '../../../../../../../shared/components/HelpTip/HelpTip';
import { CreatorGalleryForm, CreatorGallerySection } from '../../../../../../../shared/types/creatorGallery';

type FormLimitFreeFilesProps = {
  section: CreatorGallerySection;
};

export const FormLimitFreeFiles: React.FC<FormLimitFreeFilesProps> = ({
  section,
}) => {
  const { t } = useTranslation('createNewGallery');

  const {
    values,
    errors,
    handleChange,
    handleBlur,
    touched,
    setFieldValue,
  } = useFormikContext<CreatorGalleryForm>();

  const sectionIndex = useMemo(
    () => values.sections.findIndex((item) => item.id === section.id),
    [values.sections],
  );

  const sectionErrorsAndTouched = useMemo(
    () => getSectionErrorsAndTouched(errors, touched, sectionIndex),
    [errors, touched, sectionIndex],
  );
  const handleCheck = (e: ChangeEvent<HTMLInputElement>) => {
    handleChange(e);
    setFieldValue(`sections[${sectionIndex}].hasFreeFiles`, true);
    console.log(section);
    setFieldValue(`sections[${sectionIndex}].freeFilesRemaining`, Number(e.currentTarget.value || 0));
  };

  return (
    <Grid item xs={10} id="limitFreeFilesThirdStep">
      <div style={{
        display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'space-between',
      }}
      >
        <div
          style={{
            display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%',
          }}
        >
          <CheckboxTextWrapper>
            {t('thirdStep.checkbox')}
          </CheckboxTextWrapper>
          <div style={{ marginBottom: '18px', marginRight: '28px' }}>
            <HelpTip text="Make all files from this section free until a specified number of files have been added to download cart" />
          </div>
        </div>
        <CommonInput
          value={section.freeFileLimit || ''}
          name={`sections[${sectionIndex}].freeFileLimit`}
          handleChange={handleCheck}
          handleBlur={handleBlur}
          type="text"
          direction="row"
          width={180}
          error={sectionErrorsAndTouched.error?.freeFileLimit}
          touched={sectionErrorsAndTouched.wasTouched?.freeFileLimit}
          withoutMargins
          helpText="Make all files from this section free until a specified number of files have been added to download cart"
        />
      </div>
    </Grid>
  );
};
