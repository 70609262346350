import { useState } from 'react';
import { useGalleryAppDispatch } from '../../../../shared/hooks/useTypedSelector';
import { fetchAddToFav, fetchRemoveFromFav } from '../../../Client/redux/interactions/interactionsClientThunk';
import { ClientGalleryFile } from '../../../../shared/types';
import { FileBySection, FileBySectionPut } from '../../../../shared/types/interactions/interactions';

type ReturnObj = {
  isLiked: boolean;
  handleLike: (fileId: ClientGalleryFile) => void;
};

export const useLike = (isFileLiked: boolean): ReturnObj => {
  const [isLiked, setIsLiked] = useState(!!isFileLiked); // temporary
  const dispatch = useGalleryAppDispatch();

  const handleLike = (file: ClientGalleryFile) => {
    const req: FileBySectionPut = {
      sectionId: file.sectionId || '',
      file: file.id,
    };
    const update: FileBySection = {
      sectionId: file.sectionId || '',
      file,
    };
    if (isLiked || file.isFavourited) {
      dispatch(fetchRemoveFromFav([file]));
      setIsLiked(false);
    } else {
      dispatch(
        fetchAddToFav({ favs: [req], updateStateData: [update] }),
      );
      setIsLiked(true);
    }
  };

  return {
    isLiked,
    handleLike,
  };
};
