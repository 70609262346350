import { combineReducers } from '@reduxjs/toolkit';
import commentsSlice from '../../GalleryView/redux/comments/commentsSlice';
import signInClientSlice from './signIn/signInClientSlice';
import onboardingSlice from './onboarding/onboardingSlice';
import swiperSlice from '../../GalleryView/redux/gallery/swiperSlice';
import gallerySlice from '../../GalleryView/redux/gallery/gallerySlice';
import interactionsClientSlice from './interactions/interactionsClientSlice';

export const rootReducer = combineReducers({
  onboarding: onboardingSlice,
  signIn: signInClientSlice,
  comments: commentsSlice,
  swiper: swiperSlice,
  gallery: gallerySlice,
  interactionsClient: interactionsClientSlice,
});
