import { AxiosResponse } from 'axios';
import { CartReq } from '../../shared/types/client';
import { clientInstance } from '../base/instance';
import { interactionsUrl } from '../../constants/urls';
import { ClientInteraction, DeleteInteractionItemDto, ModifyInteractionPutDto } from '../../shared/types/interactions/interactions';

export const clientInteractions = {
  getInteractions: (galleryId: string): Promise<AxiosResponse<ClientInteraction>> => clientInstance.get(`${interactionsUrl}/${galleryId}`),
  modifyInteractions: (interactionId: string, data: ModifyInteractionPutDto): Promise<AxiosResponse<ClientInteraction>> => clientInstance.put(`${interactionsUrl}/${interactionId}`, {
    galleryId: data.galleryId,
    email: data.email,
    views: data.views || [],
    favourites: data.favourites || [],
    cartFiles: data.cartFiles || [],
    limitedFreeCartFiles: data.limitedFreeCartFiles || [],
    comments: data.comments || null,
    downloads: [],
  }),
  deleteInteractionItem: (interactionId: string, data: DeleteInteractionItemDto): Promise<AxiosResponse<any>> => clientInstance.put(`${interactionsUrl}/delete/${interactionId}`, data),
};
