import React from 'react';
import { RoundAvatar } from '../../../../shared/components/RoundAvatar/RoundAvatar';
import {
  CommentDateAndTimeWrapper,
  CommentDeleteButton,
  CommentItemContainer,
  CommentMessageAndDateWrapper,
  CommentMessageWrapper,
} from './styled';
import {
  getFormattedDateOrDateName,
} from '../../../../shared/utils';
import { LittleGreyText } from '../../../../shared/styles';
import { DeleteIcon } from '../../../../shared/icons';
import { GalleryComment } from '../../../../shared/types/commonGallery';
import { useTypedSelectorClient, useTypedSelectorCreator } from '../../../../shared/hooks/useTypedSelector';
import { PinkLoader } from '../../../../shared/components/Loaders/PinkLoader/PinkLoader';

type CommentItemProps = {
  comment: GalleryComment;
  dateFormat: string;
  deleteComment: (commentId: string) => void;
  clientEmail?: string;
  creatorEmail?: string;
  creatorBusinessName: string;
  timezone?: string;
};

export const CommentItem: React.FC<CommentItemProps> = ({
  comment,
  dateFormat,
  deleteComment,
  clientEmail,
  creatorEmail,
  timezone,
  creatorBusinessName,
}) => {
  const formattedDate = getFormattedDateOrDateName(
    comment.date,
    dateFormat,
    timezone,
    true,
  );
  const isLoadingClientComment = useTypedSelectorClient((state) => state?.comments?.loadingDelete);
  const isLoadingCreatorComment = useTypedSelectorCreator(
    (state) => state?.interactions?.loadingDelete,
  );

  const userName = comment.creatorMessage ? creatorBusinessName || creatorEmail : clientEmail;

  return (
    <CommentItemContainer>
      <RoundAvatar
        userName={userName || 'Anonym'}
        urlPhoto=""
        size="medium"
        border={comment.creatorMessage}
      />
      <CommentMessageAndDateWrapper>
        <CommentMessageWrapper>{comment.text}</CommentMessageWrapper>
        <CommentDateAndTimeWrapper>
          <LittleGreyText>{formattedDate}</LittleGreyText>
        </CommentDateAndTimeWrapper>
      </CommentMessageAndDateWrapper>
      {((creatorEmail && comment.creatorMessage) || (clientEmail && !comment.creatorMessage))
        && (
          <CommentDeleteButton type="button" onClick={() => deleteComment(comment.id)}>
            {isLoadingClientComment || isLoadingCreatorComment ? <PinkLoader size="xs" /> : <DeleteIcon />}
          </CommentDeleteButton>
        )}
    </CommentItemContainer>
  );
};
