import React, { MouseEvent } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { SignOutButton, useAuth } from '@clerk/clerk-react';
import * as urls from '../../../../../constants/urls';
import {
  LogoutButton,
  SidebarLinkIcon,
  SidebarMenuWrapper,
  SidebarMenuItem,
  PinkButton,
} from './styled';
import {
  ActiveGalleriesIcon,
  DraftGalleriesIcon,
  LogoutIcon,
  PlusIcon,
  SalesIcon,
  SettingsIcon,
  SupportIcon,
} from '../../../../../shared/icons';
import { fetchCreateCreatorGallery } from '../../../redux/gallery/singleGallery/singleGalleryCreatorThunk';
import { useCreatorAppDispatch, useTypedSelectorCreator } from '../../../../../shared/hooks/useTypedSelector';
import { fetchRedirectToOnboarding } from '../../../redux/userCreator/signUp/signUpCreatorThunk';
import { CustomTooltip } from '../../../../../shared/components/MaterialCustomTooltip/MaterialCustomTooltip';
import { PinkLoader } from '../../../../../shared/components/Loaders/PinkLoader/PinkLoader';
import { CoverWindow } from '../../../../../shared/components/LoadingForRequest/styled';

export const SidebarMenu: React.FC = () => {
  const { t } = useTranslation('sidebar');
  const navigate = useNavigate();
  const dispatch = useCreatorAppDispatch();
  const { pathname } = useLocation();
  const creator = useTypedSelectorCreator((state) => state.creator?.currentUser);
  const redirectLoading = useTypedSelectorCreator((state) => state.signUpCreator.redirectLoading);
  const redirectError = useTypedSelectorCreator((state) => state.signUpCreator.redirectError);

  const { getToken } = useAuth();

  const handleRedirectToOnboarding = () => {
    dispatch(fetchRedirectToOnboarding(getToken));
  };

  const handleAddNewGallery = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const newId = uuidv4();
    dispatch(fetchCreateCreatorGallery({ id: newId, getToken }));
    navigate(`${urls.newGalleryUrl}/${newId}`, { state: { status: 'new' } });
  };

  const helpText = (
    <Trans t={t} i18nKey="stripeRegistration">
      <PinkButton type="button" onClick={handleRedirectToOnboarding}>Complete Stripe registration</PinkButton>
      {' '}
      to create and send a gallery
    </Trans>
  );

  return (
    <>
      <SidebarMenuWrapper>
        <SidebarMenuItem
          disabled={!creator?.onboarded}
          isActive={pathname.includes(urls.newGalleryUrl)}
        >
          <CustomTooltip title={helpText} placement="right" disableHoverListener={creator?.onboarded}>
            <button
              type="button"
              onClick={!creator?.onboarded ? undefined : handleAddNewGallery}
              id="createNewGallerySidebar"
            >
              <SidebarLinkIcon>
                <PlusIcon />
              </SidebarLinkIcon>
              <span>{t('createNewGallery')}</span>
            </button>
          </CustomTooltip>
        </SidebarMenuItem>
        <SidebarMenuItem>
          <NavLink
            to={urls.salesUrl}
            id="salesSidebar"
            style={{ width: 'fit-content' }}
          >
            <SidebarLinkIcon>
              <SalesIcon />
            </SidebarLinkIcon>
            <span>{t('sales')}</span>
          </NavLink>
        </SidebarMenuItem>
        <SidebarMenuItem>
          <NavLink
            to={urls.activeGalleriesUrl}
            id="activeGalleriesSidebar"
            style={{ width: 'fit-content' }}
          >
            <SidebarLinkIcon>
              <ActiveGalleriesIcon />
            </SidebarLinkIcon>
            <span>{t('activeGalleries')}</span>
          </NavLink>
        </SidebarMenuItem>
        <SidebarMenuItem>
          <NavLink
            to={urls.draftGalleriesUrl}
            id="draftGalleriesSidebar"
            style={{ width: 'fit-content' }}
          >
            <SidebarLinkIcon>
              <DraftGalleriesIcon />
            </SidebarLinkIcon>
            <span>{t('draftGalleries')}</span>
          </NavLink>
        </SidebarMenuItem>
        <SidebarMenuItem>
          <NavLink to={urls.settingsUrl}>
            <SidebarLinkIcon>
              <SettingsIcon />
            </SidebarLinkIcon>
            <span>{t('settings')}</span>
          </NavLink>
        </SidebarMenuItem>
        <SidebarMenuItem>
          <NavLink
            to={urls.supportUrl}
            id="supportSidebar"
            style={{ width: 'fit-content' }}
          >
            <SidebarLinkIcon>
              <SupportIcon />
            </SidebarLinkIcon>
            <span>{t('support')}</span>
          </NavLink>
        </SidebarMenuItem>
      </SidebarMenuWrapper>
      <SignOutButton>
        <LogoutButton type="button">
          <LogoutIcon />
          <span>{t('logout')}</span>
        </LogoutButton>
      </SignOutButton>
      {redirectLoading && (
        <CoverWindow>
          <PinkLoader color="pink" />
        </CoverWindow>
      )}
    </>
  );
};
