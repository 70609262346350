import styled from '@emotion/styled';
import {
  Grid,
  ComponentsProps,
  ComponentsOverrides,
  Theme,
  ComponentNameToClassKey,
} from '@mui/material';
import { createTheme } from '@mui/material/styles';
import * as variables from '../../../constants/styles';

export type ThemeComponent<
  TComponentName extends keyof ComponentNameToClassKey | 'MuiCssBaseline',
> = {
  defaultProps?: ComponentsProps[TComponentName];
  styleOverrides?: ComponentsOverrides<Theme>[TComponentName];
};

export const MuiPopper: ThemeComponent<'MuiPopper'> = {
  defaultProps: {
    placement: 'top',
  },
  styleOverrides: {
    root: {
      background: 'red',
    },
  },
};
export const MuiTooltip: ThemeComponent<'MuiTooltip'> = {
  defaultProps: {
    placement: 'top',
  },
  styleOverrides: {
    tooltip: {
      background: 'red',
    },
  },
};

export const theme = createTheme({
  components: { MuiPopper, MuiTooltip },
  breakpoints: {
    values: {
      xs: 250,
      sm: 560, // mobile
      md: 750, // tablet
      lg: 1000,
      xl: 1400, // desktop
    },
  },
});

export const GlobalStyle = `
  html {
    margin: 0;
  }
  body {
    min-height: 100vh;
    background: ${variables.colorGreyLight};
    background-color: ${variables.colorGreyLight};
    margin: 0;
    padding: 0;
    font-family: ${variables.mainFont};
    height: 100%;
    min-height: 100vh;
    font-weight: normal;
    font-style: normal;
    ${window.location.origin.includes('staging') ? `
    &::after {
      content: "Staging";
      z-index: 1000;
      top: 0;
      left: calc(50% - 90px);
      width: 180px;
      height: 25px;
      background-color: #ffba10;
      position: absolute;
      padding: 0px 20px;
      taxt-align: center;
      font-weight: 500;
      display: flex;
      justify-content: center;
      font-size: 14px;
    }
` : ''}
    & #root {
      box-sizing: border-box;
      background-color: ${variables.colorGreyLight};
      margin: 0;
      padding: 0;
      width: 100%;
      height: 100%;  
      min-height: 100vh;
      
      & > div {
        box-sizing: border-box;
        min-height: 100vh;
        height: 100%;  
      }
    }
    & p {
      margin-block-start: 0.5em;
      margin-block-end: 0.5em;
    }
    & #modal-root {
      position: relative;
      z-index: 999;
      & > div {
        background-color: rgba(0,0,0,0.5);
        position: fixed;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    
    & a {
      outline: none;
      transition: 0.3s;
      text-decoration: none;
      cursor: pointer;
      color: ${variables.textBlack};
    }
    & button {
      outline: none;
      cursor: pointer;
      border: none;
      background: transparent;
      font-family: ${variables.mainFont};
    }
    & input, & textarea {
      outline: none;
      font-family: ${variables.mainFont};
      display: flex;
      align-items: center;
      font-size: 15px;
      margin: 0;
      color: ${variables.textGrey};
    }
    & svg {
      color: ${variables.textGrey};
    }
  }
`;

export const GridFullHeight = styled(Grid)`
  height: 100% !important;
  min-height: 100% !important;
  min-height: 100hv !important;
  padding-bottom: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
`;
export const GridItemFullHeight = styled(Grid)`
  padding-bottom: 0 !important;
  padding-top: 0 !important;
`;

// export const BaseGrid = styled()
