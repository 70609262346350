import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PinkLoader } from '../../../../../../../../../shared/components/Loaders/PinkLoader/PinkLoader';
import { useTypedSelectorCreator } from '../../../../../../../../../shared/hooks/useTypedSelector';
import { CommentsOrIdeasListWrapper } from './styled';
import { CommentsItem } from './DashboardCommentItem';
import { SimpleBlackText } from '../../../../../../../../../shared/styles';
import { CreatorCommentGet } from '../../../../../../../../../shared/types/client';

type CommentsTab = {
  galleryId: string;
}

export const CommentsTab: React.FC<CommentsTab> = ({
  galleryId,
}) => {
  const { t } = useTranslation('gallery');
  const comments = useTypedSelectorCreator((state) => state.interactions.comments);
  const isLoading = useTypedSelectorCreator((state) => state.interactions.loadingGet);
  const mapComments: CreatorCommentGet[] = useMemo(() => {
    const res: CreatorCommentGet[] = [];
    comments?.forEach((item) => {
      item.messages.forEach((itemSecond) => {
        itemSecond.messages.forEach((itemThird) => {
          if (!itemThird.creatorMessage) {
            res.push({
              message: itemThird.text,
              id: itemThird.id,
              date: itemThird.date,
              fileId: itemSecond.fileId,
              email: item.email,
              sectionId: itemSecond.sectionId,
              interactionId: item.interactionId,
            });
          }
        });
      });
    });
    return res;
  }, [comments]);

  if (isLoading) {
    return (
      <CommentsOrIdeasListWrapper style={{
        width: 'calc(100% - 10px)', display: 'flex', alignItems: 'center', justifyContent: 'center',
      }}
      >
        <PinkLoader />
      </CommentsOrIdeasListWrapper>
    );
  }

  return (
    <CommentsOrIdeasListWrapper>
      {mapComments?.length ? (
        mapComments.map((item) => (
          <CommentsItem
            galleryId={galleryId}
            key={item.id}
            comment={item}
          />
        ))
      ) : (
        <SimpleBlackText>{t('noComments')}</SimpleBlackText>
      )}
    </CommentsOrIdeasListWrapper>
  );
};
