import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { useAuth } from '@clerk/clerk-react';
import {
  LoaderFullWidthContainer,
  TabContentWrapper,
  TabWrapper,
  TitleText,
} from '../../../../../../shared/styles';
import { ActiveGalleriesCards } from './ActiveGalleriesCards';
import { PinkLoader } from '../../../../../../shared/components/Loaders/PinkLoader/PinkLoader';
import { useCreatorAppDispatch, useTypedSelectorCreator } from '../../../../../../shared/hooks/useTypedSelector';
import { selectActiveGalleries } from '../../../../redux/gallery/allGaleries/galleryCreatorSlice';
import { fetchGetCreatorGalleries } from '../../../../redux/gallery/allGaleries/galleryCreatorThunk';

export const ActiveGalleries: React.FC = () => {
  const { t } = useTranslation('activeGalleries');
  const dispatch = useCreatorAppDispatch();
  const { getToken } = useAuth();

  const activeGalleries = useTypedSelectorCreator(
    (state) => selectActiveGalleries(state.galleries),
  );
  const isLoading = useTypedSelectorCreator((state) => state.galleries.loadingGet);

  useEffect(() => {
    dispatch(fetchGetCreatorGalleries(getToken));
  }, []);

  return (
    <TabWrapper>
      <TitleText>{t('title')}</TitleText>
      <TabContentWrapper>
        {isLoading ? (
          <LoaderFullWidthContainer><PinkLoader /></LoaderFullWidthContainer>
        ) : (
          <Grid container spacing={4}>
            <ActiveGalleriesCards galleries={activeGalleries} />
          </Grid>
        )}
      </TabContentWrapper>
    </TabWrapper>
  );
};
