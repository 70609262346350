import {
  getDataDay,
  getDataMonth,
  getDataWeek,
} from '../../../modules/Creator/containers/CreatorAccount/Tabs/Sales/SalesChart/data';

export const getDataByScale = (
  scale: number,
  salesPerDay: number[],
  salesPerWeekDay: number[],
  salesPerMonth: number[],
) => {
  const dataDay = getDataDay(salesPerDay);
  const dataWeek = getDataWeek(salesPerWeekDay);
  // const dataMonth = getDataMonth(salesPerMonth);

  switch (scale) {
    case 0:
      return dataDay;
    case 1:
      return dataWeek;
    // case 2:
    //   return dataMonth;
    default:
      return [];
  }
};
