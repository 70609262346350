import { AxiosResponse } from 'axios';
import { instance } from '../base/instance';
import {
  ClientInteraction, CreatorInteractionsGet, DeleteInteractionItemDto, ModifyInteractionPutDto,
} from '../../shared/types/interactions/interactions';
import { interactionsUrl } from '../../constants/urls';

export const creatorInteractions = {
  getInteractions: (galleryId: string, token: string): Promise<AxiosResponse<CreatorInteractionsGet>> => instance.get(`${interactionsUrl}/creators/${galleryId}`, { headers: { crossDomain: true, Authorization: `${token}` } }),
  modifyInteractions: (interactionId: string, data: ModifyInteractionPutDto, token: string): Promise<AxiosResponse<ClientInteraction>> => instance.put(`${interactionsUrl}/creators/${interactionId}`, data, { headers: { crossDomain: true, Authorization: `${token}` } }),
  deleteInteractionItem: (interactionId: string, data: DeleteInteractionItemDto, token: string): Promise<AxiosResponse<any>> => instance.put(`${interactionsUrl}/creators/delete/${interactionId}`, data, { headers: { crossDomain: true, Authorization: `${token}` } }),
};
