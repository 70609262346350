import { createAsyncThunk } from '@reduxjs/toolkit';
import { GetToken } from '@clerk/types';
import { orders } from '../../../../api/creator/creator';

export const fetchGetOrdersByGalleryId = createAsyncThunk(
  'orders/fetchGetByGalleryIdOrders',
  async (
    {
      getToken,
      limit,
      page,
      galleryId,
    }: { getToken: GetToken; limit?: number; page?: number, galleryId: string },
    thunkApi,
  ) => {
    try {
      const token = (await getToken()) || '';
      const response = await orders.getOrdersByGalleryId(token, page || 1, limit || 10, galleryId);
      return response.data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  },
);

export const fetchGetOrdersByCreatorId = createAsyncThunk(
  'orders/fetchGetByCreatorIdOrders',
  async (
    {
      getToken,
      limit,
      page,
    }: { getToken: GetToken; limit?: number; page?: number },
    thunkApi,
  ) => {
    try {
      const token = (await getToken()) || '';
      const response = await orders.getOrdersByCreatorId(token, page || 1, limit || 10);
      return response.data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  },
);
