import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ErrorType } from '../../../../shared/types';
import {
  fetchAddToCart,
  fetchAddToFav,
  fetchGetClientInteractions,
  fetchRemoveFromCart,
  fetchRemoveFromFav,
} from './interactionsClientThunk';
import {
  ClientInteraction,
  FileBySection,
  FileBySectionPut,
} from '../../../../shared/types/interactions/interactions';

type initialState = {
  interaction: ClientInteraction;
  loadingGet: boolean;
  loadingCartPut: boolean;
  loadingFavPut: boolean;
  error: ErrorType;
};

export const initialState: initialState = {
  interaction: {
    _id: '',
    galleryId: '',
    email: '',
    favourites: [],
    cartFiles: [],
    limitedFreeCartFiles: [],
  },
  loadingGet: false,
  loadingCartPut: false,
  loadingFavPut: false,
  error: null,
};

const interactionsClientSlice = createSlice({
  name: 'interactionsClientSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchGetClientInteractions.fulfilled,
      (state, action: PayloadAction<ClientInteraction>) => {
        state.interaction.email = action.payload.email;
        state.interaction._id = action.payload._id;
        state.interaction.cartFiles = action.payload.cartFiles;
        state.interaction.favourites = action.payload.favourites;
        state.interaction.limitedFreeCartFiles = action.payload.limitedFreeCartFiles;
        state.interaction.galleryId = action.payload.galleryId;
        state.error = '';
        state.loadingGet = false;
      },
    );
    builder.addCase(
      fetchGetClientInteractions.rejected,
      (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loadingGet = false;
      },
    );
    builder.addCase(fetchGetClientInteractions.pending, (state) => {
      state.loadingGet = true;
    });
    builder.addCase(
      fetchAddToCart.fulfilled,
      (
        state,
        action: PayloadAction<{
          cartFiles: FileBySection[];
          limitedFreeCartFiles: Array<FileBySection>;
        }>,
      ) => {
        state.error = null;
        state.loadingCartPut = false;
        if (action.payload.cartFiles.length) {
          state.interaction.cartFiles = [
            ...state.interaction.cartFiles,
            ...action.payload.cartFiles,
          ];
        }
        if (action.payload.limitedFreeCartFiles.length) {
          state.interaction.limitedFreeCartFiles = [
            ...state.interaction.limitedFreeCartFiles,
            ...action.payload.limitedFreeCartFiles,
          ];
        }
      },
    );
    builder.addCase(
      fetchAddToCart.rejected,
      (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loadingCartPut = false;
      },
    );
    builder.addCase(fetchAddToCart.pending, (state) => {
      state.loadingCartPut = true;
    });
    builder.addCase(
      fetchRemoveFromCart.fulfilled,
      (
        state,
        action: PayloadAction<{
          cartFiles: string[];
          limitedFreeCartFiles: string[];
        }>,
      ) => {
        state.error = null;
        state.loadingCartPut = false;
        if (action.payload.cartFiles.length) {
          state.interaction.cartFiles = state.interaction.cartFiles.filter(
            (item) => !action.payload.cartFiles.includes(item.file.id),
          );
        }
        if (action.payload.limitedFreeCartFiles.length) {
          state.interaction.limitedFreeCartFiles = state.interaction.limitedFreeCartFiles.filter(
            (item) => !action.payload.limitedFreeCartFiles.includes(item.file.id),
          );
        }
      },
    );
    builder.addCase(
      fetchRemoveFromCart.rejected,
      (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loadingCartPut = false;
      },
    );
    builder.addCase(fetchRemoveFromCart.pending, (state) => {
      state.loadingCartPut = true;
    });
    builder.addCase(
      fetchAddToFav.fulfilled,
      (state, action: PayloadAction<FileBySection[]>) => {
        state.error = null;
        state.loadingFavPut = false;
        state.interaction.favourites = [
          ...state.interaction.favourites,
          ...action.payload,
        ];
      },
    );
    builder.addCase(
      fetchAddToFav.rejected,
      (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loadingFavPut = false;
      },
    );
    builder.addCase(fetchAddToFav.pending, (state) => {
      state.loadingFavPut = true;
    });
    builder.addCase(
      fetchRemoveFromFav.fulfilled,
      (state, action: PayloadAction<FileBySectionPut[]>) => {
        state.error = null;
        state.loadingFavPut = false;
        const filesIds = action.payload.map((item) => item.file);
        state.interaction.favourites = state.interaction.favourites.filter(
          (item) => !filesIds.includes(item.file.id),
        );
      },
    );
    builder.addCase(
      fetchRemoveFromFav.rejected,
      (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loadingFavPut = false;
      },
    );
    builder.addCase(fetchRemoveFromFav.pending, (state) => {
      state.loadingFavPut = true;
    });
  },
});

export default interactionsClientSlice.reducer;
