const oneDayInMs = 24 * 60 * 60000;

// eslint-disable-next-line no-shadow
export enum ReminderTimeEnum {
  oneWeek = 7 * oneDayInMs,
  threeDays = 3 * oneDayInMs,
  twoDays = 2 * oneDayInMs,
  oneDay = oneDayInMs,
}

export enum CancelRenewalEnum {
  noProjects = "I don't have any upcoming projects",
  notUseEnough = "I don't use enough",
  difPlatform = "I'm subscribed to a different platform",
  uncomfortable = 'I find delivrable uncomfortable to use',
  cannotAfford = "I can't afford it",
  other = 'Other (please specify)',
}

export type ReminderTime = 'oneWeek' | 'threeDays' | 'twoDays' | 'oneDay';

export enum ErrorCode {
  Warning = 'warning',
  Beta = 'beta',
}
