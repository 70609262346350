import React, { useEffect } from 'react';
import { BaseModalWindow } from '../../../../shared/components/BaseModalWindow/BaseModalWindow';
import { GalleryComment } from '../../../../shared/types';
import { useGetCommentWindowStyle } from '../../hooks/useGetCommentWindowStyle';
import { CommentContainer } from './CommentContainer';
import { FileAndCommentsWindowWrapper } from './styled';

type CommentAreaWindowProps = {
  isOpen: boolean;
  handleClose: () => void;
  comments: GalleryComment[];
  dateFormat: string;
  addComment: (comment: string, resetForm: () => void) => void;
  deleteComment: (commentId: string) => void;
};

export const CommentAreaWindow: React.FC<CommentAreaWindowProps> = ({
  isOpen,
  handleClose,
  comments,
  dateFormat,
  addComment,
  deleteComment,
}) => {
  const { styles } = useGetCommentWindowStyle();
  useEffect(() => {
    const modalWindow = document.getElementsByClassName('MuiPaper-root');
    modalWindow[0]?.scrollTo({
      top: modalWindow[0].scrollHeight,
      behavior: 'smooth',
    });
  }, []);

  return (
    <BaseModalWindow
      fullScreen
      isOpen={isOpen}
      handleClose={handleClose}
      style={{
        root: styles,
        paper: styles,
      }}
    >
      <FileAndCommentsWindowWrapper>
        <CommentContainer
          addComment={addComment}
          deleteComment={deleteComment}
          handleClose={handleClose}
          comments={comments}
          dateFormat={dateFormat}
        />
      </FileAndCommentsWindowWrapper>
    </BaseModalWindow>
  );
};
