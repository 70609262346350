import React, { useMemo, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { MediumBlackText } from '../../../../../../shared/styles';
import { isArrayEmpty, isImage, isVideo } from '../../../../../../shared/utils';
import { FilterButtons } from './FilterButtons';
import {
  GallerySectionHeader,
  SectionTitleLarge,
  LineGrey,
  FiltersAndFreeFilesWrapper,
  GallerySectionTitle,
  GallerySectionFreeFiles,
} from './styled';
import { ClientGallerySection } from '../../../../../../shared/types/clientGallery';
import { ClientGalleryFile } from '../../../../../../shared/types/commonGallery';
import { useGetFreeFilesRemaning } from '../../../../hooks/useGetFreeFilesRemaning';

type SectionItemHeaderProps = {
  section: ClientGallerySection;
  arrImagesAndVideos: ClientGalleryFile[];
  arrDocuments: ClientGalleryFile[];
  handleChooseFilter: (e: MouseEvent<HTMLButtonElement>) => void;
};

export const SectionItemHeader: React.FC<SectionItemHeaderProps> = ({
  section,
  arrImagesAndVideos,
  arrDocuments,
  handleChooseFilter,
}) => {
  const { t } = useTranslation('gallery');
  const remainFreeFiles = useGetFreeFilesRemaning(section.id, section.freeFileLimit || 0);
  const { isAnyVideos, isAnyPhotos } = useMemo(() => {
    const video = arrImagesAndVideos.find((item) => isVideo(item));
    const image = arrImagesAndVideos.find((item) => isImage(item));

    return { isAnyVideos: !!video, isAnyPhotos: !!image };
  }, [arrImagesAndVideos]);
  return (
    <GallerySectionHeader>
      <GallerySectionTitle>
        <SectionTitleLarge>{section.title}</SectionTitleLarge>
        <LineGrey />
      </GallerySectionTitle>
      <FiltersAndFreeFilesWrapper>
        <GallerySectionFreeFiles>
          {section.freeFileLimit ? (
            <MediumBlackText>
              {remainFreeFiles}
              {' '}
              /
              {' '}
              {section.freeFileLimit}
              {' '}
              {t('freeFiles')}
            </MediumBlackText>
          ) : null}
        </GallerySectionFreeFiles>
        <FilterButtons
          isAnyPhotos={isAnyPhotos}
          isAnyVideos={isAnyVideos}
          isAnyIdeas={!isArrayEmpty(section?.ideas)}
          isAnyOtherFiles={!isArrayEmpty(arrDocuments)}
          handleChoose={handleChooseFilter}
        />
      </FiltersAndFreeFilesWrapper>
    </GallerySectionHeader>
  );
};
