import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { SimpleBlackText } from '../../../../../../shared/styles';
import { GalleryCard } from '../../../../../../shared/components/GalleryCard/GalleryCard';
import { Dashboard } from './Dashboard/Dashboard';
import { CreatorGalleryGet } from '../../../../../../shared/types/creatorGallery';

type ActiveGalleriesCardsProps = {
  galleries: CreatorGalleryGet[];
}

export const ActiveGalleriesCards: React.FC<ActiveGalleriesCardsProps> = ({ galleries }) => {
  const { t } = useTranslation('activeGalleries');
  return (
    galleries.length
      ? (
        galleries.map((item) => (
          <Grid
            key={item?.id}
            item
            lg={6}
            md={12}
          >
            <GalleryCard
              title={item?.name}
              coverFile={item.coverFile.desktop || item.coverFile.original}
              // wasWatchedByClient={}
                // TODO: check on the backend before response if a gallery was watched
                // wasWatchedByClient={item?.wasWatched}
              galleryId={item?.id}
            >
              <Dashboard
                gallery={item}
              />
            </GalleryCard>
          </Grid>
        ))
      )
      : (
        <Grid item sm={6}>
          <SimpleBlackText>{t('noActiveGalleries')}</SimpleBlackText>
        </Grid>
      )
  );
};
