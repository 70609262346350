import React, { useContext } from 'react';
import { GridMgTop } from './styled';
import * as url from '../../../../../../constants/urls';
import { GalleryCardCustom } from './GalleryCardCustom';
import { CreatorOnboardingContext } from '../../../../context/CreatorOnboardingContext';
import { fakeCreatorGalleries } from '../../../../../../constants/walkThroughCards/fakeGalleryData';
import { useTypedSelectorCreator } from '../../../../../../shared/hooks/useTypedSelector';
import { selectActiveGalleries, selectDraftGalleries } from '../../../../redux/gallery/allGaleries/galleryCreatorSlice';

export const GalleriesList: React.FC = () => {
  const { isStudy } = useContext(CreatorOnboardingContext);
  const draftGalleries = useTypedSelectorCreator((state) => selectDraftGalleries(state.galleries));
  const activeGalleries = useTypedSelectorCreator(
    (state) => selectActiveGalleries(state.galleries),
  );

  const checkedStudyActiveGalleries = isStudy ? fakeCreatorGalleries : activeGalleries;
  const checkedStudyDraftGalleries = isStudy ? fakeCreatorGalleries : draftGalleries;

  return (
    <GridMgTop container spacing={4} justifyContent="space-between">
      <GalleryCardCustom
        gallery={
          checkedStudyActiveGalleries?.length
            ? checkedStudyActiveGalleries[0]
            : null
        }
        galleriesNumber={checkedStudyActiveGalleries?.length || 0}
        type="Active"
        toUrl={url.activeGalleriesUrl}
      />
      <GalleryCardCustom
        gallery={
          checkedStudyDraftGalleries?.length
            ? checkedStudyDraftGalleries[0]
            : null
        }
        galleriesNumber={checkedStudyDraftGalleries?.length || 0}
        type="Draft"
        toUrl={url.draftGalleriesUrl}
      />
    </GridMgTop>
  );
};
