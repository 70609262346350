import { createAsyncThunk } from '@reduxjs/toolkit';
import { GetToken } from '@clerk/types';
import { creatorInteractions } from '../../../../../api/interactions/interactions';
import {
  GalleryComment,
  CreatorCommentReturnResPut,
  ViewCreator,
  CommentsByFile,
} from '../../../../../shared/types';

export const fetchCreatorAddComment = createAsyncThunk(
  'comments/fetchAddComment',
  async (
    data: {
      comment: GalleryComment;
      sectionId: string;
      fileId: string;
      _id: string;
      galleryId: string;
      email: string;
      getToken: GetToken;
    },
    thunkApi,
  ) => {
    try {
      const token = await data.getToken();
      const reqData: CommentsByFile = {
        sectionId: data.sectionId,
        fileId: data.fileId,
        messages: [data.comment],
      };
      const response = await creatorInteractions.modifyInteractions(
        data._id,
        {
          comments: { sectionId: data.sectionId, fileId: data.fileId, message: data.comment },
          galleryId: data.galleryId,
          email: data.email,
          id: data._id,
        },
        token || '',
      );
      const returnRes: CreatorCommentReturnResPut = {
        sectionId: data.sectionId,
        interactionId: data._id,
        email: data.email,
        fileId: data.fileId,
        comment: data.comment,
      };

      return returnRes;
    } catch (err: any) {
      console.log(err?.response?.data?.message);
      return thunkApi.rejectWithValue("The message wasn't sent");
    }
  },
);

export const fetchCreatorDeleteComment = createAsyncThunk(
  'comments/fetchDeleteComment',
  async (
    data: {
      commentId: string;
      sectionId: string;
      fileId: string;
      getToken: GetToken;
      _id: string;
      galleryId: string;
      email: string;
    },
    thunkApi,
  ) => {
    try {
      const token = await data.getToken();
      const reqData: CommentsByFile = {
        sectionId: data.sectionId,
        fileId: data.fileId,
        messages: [],
      };
      const response = await creatorInteractions.modifyInteractions(
        data._id,
        {
          // comments: [reqData],
          galleryId: data.galleryId,
          email: data.email,
          id: data._id,
        },
        token || '',
      );

      return {
        commentId: data.commentId,
        sectionId: data.sectionId,
        fileId: data.fileId,
        interactionId: data._id,
        email: data.email,
        galleryId: data.galleryId,
      };
    } catch (err: any) {
      console.log({ err });
      return thunkApi.rejectWithValue('Failed to delete the comment');
    }
  },
);

export const fetchGetCreatorInteractions = createAsyncThunk(
  'interactionsCreator/fetchGetCreatorInteractions',
  async (
    { galleryId, getToken }: { galleryId: string; getToken: GetToken },
    thunkApi,
  ) => {
    try {
      const token = (await getToken()) || '';
      const response = await creatorInteractions.getInteractions(
        galleryId,
        token,
      );
      return response.data;
    } catch (error: any) {
      return thunkApi.rejectWithValue('Failed to get the gallery interactions');
    }
  },
);

export const fetchDeleteCreatorViews = createAsyncThunk(
  'interactionsCreator/fetchDeleteCreatorViews',
  async (
    data: {
      galleryId: string;
      email: string;
      getToken: GetToken;
      interactionId: string;
      view: ViewCreator,
    },
    thunkApi,
  ) => {
    try {
      const {
        galleryId, email, getToken, interactionId, view,
      } = data;
      const token = (await getToken()) || '';
      const response = await creatorInteractions.deleteInteractionItem(
        data.interactionId,
        { views: [email], galleryId: data.galleryId, email: data.email },
        token,
      );
      return {
        galleryId, email, interactionId, view,
      };
    } catch (error: any) {
      return thunkApi.rejectWithValue('Failed to delete the view');
    }
  },
);
