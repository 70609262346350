/* eslint-disable */
import { v4 as uuidv4 } from 'uuid';
import {
  getFormatDateForDob,
  isVideo,
} from '../../../shared/utils';
import { CreatorGalleryForm, CreatorGalleryPut } from '../../../shared/types/creatorGallery';
import { GalleryFileBaseWithUrls, GalleryFileSettings, GalleryFileSettingsUpdate, GalleryStatus } from '../../../shared/types/commonGallery';

export const getGalleryDataForRequest = (
  data: CreatorGalleryForm,
  status: GalleryStatus,
): CreatorGalleryPut => {
  const dataCopy: Omit<CreatorGalleryForm, "step"> & { step?: number } = JSON.parse(
    JSON.stringify(data),
  );
  delete dataCopy.step;

  //we dont save coverFile array, because it already created
  //during file uploading
  (dataCopy as unknown as any).coverFile = dataCopy.coverFile.original?.id || dataCopy.coverFile.desktop?.id;
  (dataCopy as unknown as CreatorGalleryPut).date = dataCopy.date
    ? getFormatDateForDob(new Date(dataCopy.date))
    : '';
  (dataCopy as unknown as CreatorGalleryPut).status = status;
  dataCopy.sections.forEach((section) => {
    // section?.ideas?.forEach((idea) => (idea.price as unknown as number) = +(idea?.price || 0));
    const filesIds = section?.files?.map((file) => file.id);
    section.files = filesIds as any;
  });
  return dataCopy as unknown as CreatorGalleryPut;
};

export const getFileObjectForUpdateRequest = (
  selectedIds: string[], settings: GalleryFileSettings,
): (GalleryFileSettingsUpdate & { id: string })[] => {
  const resSettings: GalleryFileSettingsUpdate = {
    description: settings.description,
    price: settings.price,
    instantlyDownloadable: settings.instantlyDownloadable,
    watermark: settings.watermark, 
    allowCommenting: settings.allowCommenting,
    fullWidth: settings.fullWidth, 
  };

  if (settings.hasVideoThumbnail && settings.videoThumbnailFile?.id) {
    resSettings.videoThumbnailFile = settings.videoThumbnailFile.id;
  }
  const res = selectedIds.map((id) => ({ id, ...resSettings }));
  return res;
};

export const getFormDataAndFileObjects = (
  files: FileList | File[],
): { formData: FormData; ids: string[] } => {
  const formData = new FormData();
  const ids: string[] = [];

  for (let i = 0; i < files.length; i++) {
    const id = uuidv4();

    formData.append(id, files[i]);
    ids.push(id);
  }

  return { formData, ids };
};

// export const getCoverFileObject = (fileId: string, file: File): CustomFile => ({
//   name: file.name,
//   url: getURLByFile(file),
//   type: file.type,
//   id: fileId,
// });

// export const getFilesArr = (
//   fileIds: string[],
//   files: File[],
// ): GalleryFile[] => {
//   const resultFiles: GalleryFile[] = [];

//   for (let i = 0; i < fileIds.length; i++) {
//     resultFiles.push({
//       name: files[i].name,
//       url: getURLByFile(files[i]),
//       type: files[i].type,
//       id: fileIds[i],
//       fullWidth: isVideo(files[i]),
//     });
//   }
//   return resultFiles;
// };

export const getFilteredUploadingFiles = (
  uploadingFiles: File[],
  alreadyUploadedFiles: GalleryFileBaseWithUrls[],
): File[] => {
  const filteredFiles = uploadingFiles.filter(
    (item) => !alreadyUploadedFiles.find((file) => file?.name === item?.name),
  );

  return filteredFiles;
};

type FileWithoutUrl = { name: string, fullWidth: boolean, type: string, id: string };

export const getFilesWithoutUrl = (uploadingFiles: File[], ids: string[]): FileWithoutUrl[] => {
  const resultFiles: FileWithoutUrl[] = [];

  for (let i = 0; i < ids.length; i++) {
    resultFiles.push({
      name: uploadingFiles[i].name,
      type: uploadingFiles[i].type,
      id: ids[i],
      fullWidth: isVideo(uploadingFiles[i]),
    });
  }

  return resultFiles;
};
