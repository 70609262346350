import { ClientGalleryFile, GalleryIdea } from '../../../shared/types/commonGallery';
import { FileBySection } from '../../../shared/types/interactions/interactions';

export const getAllFilesFromCart = (
  cartSections: FileBySection[],
): (ClientGalleryFile | GalleryIdea)[] => {
  let res: (ClientGalleryFile | GalleryIdea)[] = [];
  cartSections?.forEach((item) => {
    res = [...res, item.file];
  });
  return res;
};
