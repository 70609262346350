import React, { useContext } from 'react';
import { BasicDocumentCard } from '../../../../../../../shared/components/Files/BasicDocumentCard/BasicDocumentCard';
import { InvisibleOpenFileButton } from '../ImagesAndVideosList/ImageOrVideoCard/InvisibleOpenFileButton/InvisibleOpenFileButton';
import { isArrayEmpty } from '../../../../../../../shared/utils';
import { ClientStateContext } from '../../../../../../Client/context/ClientStateContext';
import { DocumentsListWrapper } from '../../../styled';
import { InstantlyDownloadableOrLike } from '../ImagesAndVideosList/ImageOrVideoCard/InstantlyDownloadableOrLike';
import { PositionedDocItems } from './styled';
import { ClientGalleryFile, Filters } from '../../../../../../../shared/types/commonGallery';

type DocumentsListProps = {
  chosenFilters: Filters[];
  arrDocuments: ClientGalleryFile[];
};

export const DocumentsList: React.FC<DocumentsListProps> = ({
  chosenFilters,
  arrDocuments,
}) => {
  const { isClientSignIn } = useContext(ClientStateContext);

  return (
    <DocumentsListWrapper>
      {isArrayEmpty(chosenFilters) || chosenFilters.includes('other')
        ? arrDocuments?.map((item) => (
          <div key={item.id}>
            <PositionedDocItems>
              <InvisibleOpenFileButton
                file={item}
                key={item.id}
              />
              <div id="doc-like">
                <InstantlyDownloadableOrLike
                  file={item}
                  isFileLiked={!!item.isFavourited}
                  instantlyDownloadable={!!item.instantlyDownloadable}
                  disabledAll={!isClientSignIn}
                  fileId={item.id}
                  iconsColor="grey"
                />
              </div>
              <BasicDocumentCard file={item} />
            </PositionedDocItems>
          </div>
        ))
        : null}
    </DocumentsListWrapper>
  );
};
