import { createAsyncThunk } from '@reduxjs/toolkit';
import { GetToken } from '@clerk/types';
import { gallery } from '../../../../../api/gallery/gallery';
import { attachGalleryFilesUrls } from '../../../../../shared/utils/gallery/setFileUrls';
import { CreatorGalleryGet } from '../../../../../shared/types/creatorGallery';

export const fetchGetCreatorGalleries = createAsyncThunk(
  'galleryCreator/fetchGetCreatorGalleries',
  async (getToken: GetToken, thunkApi) => {
    try {
      const token = (await getToken()) || '';
      const response = await gallery.getGalleries(token);
      // eslint-disable-next-line max-len
      const resGalleries = response.data.map((item) => attachGalleryFilesUrls<CreatorGalleryGet>(item));
      return resGalleries;
    } catch (error: any) {
      return thunkApi.rejectWithValue(
        error.response?.data?.message || error.message,
      );
    }
  },
);

export const fetchDeleteCreatorGallery = createAsyncThunk(
  'galleryCreator/fetchDeleteCreatorGallery',
  async ({ id, getToken }: { id: string, getToken: GetToken }, thunkApi) => {
    try {
      const token = (await getToken()) || '';
      const response = await gallery.deleteGallery(id, token);
      return id;
    } catch (error: any) {
      return thunkApi.rejectWithValue(
        { message: error.response?.data?.message || error.message, id },
      );
    }
  },
);
