import React, { useContext, useEffect } from 'react';
import { useBeforeUnload, useLocation, useParams } from 'react-router-dom';
import { useAuth } from '@clerk/clerk-react';
import { NewGalleryCreator } from './NewGalleryCreator';
import { useCreatorAppDispatch, useTypedSelectorCreator } from '../../../../../../shared/hooks/useTypedSelector';
import { fetchGetCreatorGallery } from '../../../../redux/gallery/singleGallery/singleGalleryCreatorThunk';
import { PinkLoader } from '../../../../../../shared/components/Loaders/PinkLoader/PinkLoader';
import { CreatorOnboardingContext } from '../../../../context/CreatorOnboardingContext';
import { fakeGallery } from '../../../../../../constants/walkThroughCards/fakeGalleryData';
import { galleryInitialValues } from '../../../../../../constants/initialValues';

export const NewGalleryCreatorContainer: React.FC = () => {
  const { id: galleryId } = useParams<{ id: string }>();
  const dispatch = useCreatorAppDispatch();
  const { isStudy } = useContext(CreatorOnboardingContext);
  const { getToken } = useAuth();
  const { state: locationState } = useLocation();
  const [stateBeforeUnload, setStateBeforeUnload] = React.useState(null);

  const gallery = useTypedSelectorCreator((state) => state.gallery.gallery);
  const isLoading = useTypedSelectorCreator((state) => state.gallery.loadingGet);
  const error = useTypedSelectorCreator((state) => state.gallery.error);
  const businessName = useTypedSelectorCreator(
    (state) => state.creator?.currentUser?.businessName,
  );

  useEffect(() => {
    if (!isStudy && galleryId) {
      dispatch(fetchGetCreatorGallery({ id: galleryId, getToken }));
    }
  }, [dispatch, galleryId, isStudy, fetchGetCreatorGallery, getToken, locationState?.status]);

  // save it off before users navigate away
  useBeforeUnload(
    React.useCallback(() => {
      localStorage.stuff = stateBeforeUnload;
    }, [stateBeforeUnload]),
  );

  useEffect(() => {
    if (stateBeforeUnload === null && localStorage.stuff != null) {
      setStateBeforeUnload(localStorage.stuff);
    }
  }, [stateBeforeUnload]);

  if (isLoading) {
    return <PinkLoader />;
  }

  if (error && locationState?.status === 'edited') {
    return <div>The gallery wasn&apos;t found</div>;
  }

  const initialValues = isStudy
    ? fakeGallery
    : galleryInitialValues(galleryId || '', businessName, locationState?.status === 'new' ? undefined : gallery);

  return (
    <NewGalleryCreator galleryId={galleryId || ''} currentGallery={initialValues} />
  );
};
