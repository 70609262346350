import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useDialog } from '../../hooks/useDialog';
import { DeleteIcon, EyeIcon } from '../../icons';
import {
  RoundItem,
  SimpleBlackText,
  WhiteButtonWithoutShadow,
} from '../../styles';
import { GalleryFileBaseWithUrls } from '../../types/commonGallery';
import { getFileInContainer } from '../../utils/createGallery';
import {
  ButtonWrapperWithOpacity,
  EyePositionWrapper,
  GalleryCardCover,
  GalleryCardHeader,
  GalleryCardHeaderButton,
  GalleryCardTitle,
  GalleryCardWrapper,
} from './styled';
import { useTypedSelectorCreator } from '../../hooks/useTypedSelector';
import { PinkLoader } from '../Loaders/PinkLoader/PinkLoader';
import { ErrorMessage } from '../styled';

type GalleryCardProps = {
  title: string;
  coverFile?: GalleryFileBaseWithUrls;
  buttonText?: string;
  handleClick?: () => void;
  wasWatchedByClient?: boolean;
  children?: ReactNode;
  handleDelete?: (id: string) => void;
  galleryId: string;
};

export const GalleryCard: React.FC<GalleryCardProps> = ({
  title,
  coverFile,
  buttonText,
  handleClick,
  wasWatchedByClient,
  children,
  handleDelete,
  galleryId,
}) => {
  const { t } = useTranslation('draftGalleries');
  const { setIsOpen, dialogWindow } = useDialog<string>(
    'confirmation',
    galleryId,
    handleDelete,
    t('deleteConfirmation.question'),
    t('deleteConfirmation.title'),
  );
  const deleteLoading = useTypedSelectorCreator(
    (state) => state.galleries.loadingDeleteId,
  );
  const errorDelete = useTypedSelectorCreator(
    (state) => state.galleries.errorDeleteItem,
  );
  const coverFileInContainer = coverFile
    ? getFileInContainer({ file: coverFile })
    : null;
  return (
    <>
      <GalleryCardWrapper>
        <GalleryCardHeader>
          <GalleryCardTitle>{title}</GalleryCardTitle>
          {errorDelete?.id === galleryId && errorDelete.message && (
            <div style={{ position: 'absolute', top: 'calc(100% - 8px)' }}>
              <ErrorMessage>{errorDelete.message}</ErrorMessage>
            </div>
          )}
          {handleDelete && (
            <GalleryCardHeaderButton
              disabled={deleteLoading === galleryId && !!deleteLoading}
              type="button"
              onClick={() => setIsOpen(true)}
            >
              {deleteLoading === galleryId && !!deleteLoading ? (
                <PinkLoader size="xs" />
              ) : (
                <DeleteIcon />
              )}
            </GalleryCardHeaderButton>
          )}
        </GalleryCardHeader>
        <GalleryCardCover>
          {wasWatchedByClient && (
            <EyePositionWrapper>
              <RoundItem>
                <EyeIcon />
              </RoundItem>
            </EyePositionWrapper>
          )}
          <ButtonWrapperWithOpacity>
            {children || (
              <WhiteButtonWithoutShadow type="button" onClick={handleClick}>
                {buttonText}
              </WhiteButtonWithoutShadow>
            )}
          </ButtonWrapperWithOpacity>
          {coverFileInContainer || (
            <SimpleBlackText>{t('noCoverPhoto')}</SimpleBlackText>
          )}
        </GalleryCardCover>
      </GalleryCardWrapper>
      {dialogWindow}
    </>
  );
};
