import React, {
  ReactElement, useCallback, useMemo, useState,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Fade } from '@mui/material';
import { AfterAddToCart } from '../../../../shared/styles/Elements';
import {
  useGalleryAppDispatch,
  useTypedSelectorClient,
} from '../../../../shared/hooks/useTypedSelector';
import {
  ClientGalleryFile,
  ClientGallerySection,
} from '../../../../shared/types';
import { fetchAddToCart, fetchRemoveFromCart } from '../../../Client/redux/interactions/interactionsClientThunk';
import { selectClientLimitedFreeCartFiles } from '../../../Client/redux/interactions/interactionsClientSelectors';
import { remainFilesInSections } from '../../../Client/redux/signIn/signInClientSlice';

type ReturnObj = {
  isInCart: boolean;
  handleClickCart: (
    file: ClientGalleryFile,
  ) => void;
  hoverElement?: ReactElement | null;
  isVisible: boolean;
  isLimitedFree: boolean;
};

const AfterAddToCartNotification: React.FC<{
  isVisible: boolean;
  remainFreeFiles: number;
}> = ({ isVisible, remainFreeFiles }) => {
  const { t } = useTranslation('gallery');

  return (
    <Fade in={isVisible}>
      <AfterAddToCart>
        <Trans t={t} i18nKey="moreFreeFiles">
          You have
          {' '}
          {{ files: remainFreeFiles }}
          {' '}
          more free files in this section!
        </Trans>
      </AfterAddToCart>
    </Fade>
  );
};

export const useAddToCart = (
  isFileInCart: boolean,
  sectionId: string,
  isLimitedFreeFile: boolean,
): ReturnObj => {
  const [isInCart, setIsInCart] = useState(isFileInCart);
  const [isLimitedFree, setIsLimitedFree] = useState(isLimitedFreeFile);
  const [isVisible, setIsVisible] = useState(false);
  const dispatch = useGalleryAppDispatch();
  const gallery = useTypedSelectorClient((state) => state.signIn?.gallery);
  const remainFiles = useTypedSelectorClient((state) => remainFilesInSections(
    state.signIn?.gallery,
    state.interactionsClient?.interaction?.limitedFreeCartFiles,
  ));
  const freeFiles = useTypedSelectorClient((state) => selectClientLimitedFreeCartFiles(
    state.interactionsClient?.interaction?.limitedFreeCartFiles,
  ));
  const section: ClientGallerySection | undefined = useMemo(
    () => gallery?.sections?.find((item) => item?.id === sectionId),
    [gallery],
  );
  const remainFreeFiles = remainFiles.get(section?.id);

  const showElement = () => {
    setIsVisible(true);
    setTimeout(() => setIsVisible(false), 1500);
  };

  const handleClickCart = useCallback(
    (file: ClientGalleryFile) => {
      if (!(file.isInCart || file.isLimitedFreeFile) || !(isInCart || isLimitedFree)) {
        const limitCurrentSection = freeFiles.sections.filter(
          (item) => item.sectionId === file.sectionId,
        );
        const limitedFreeCartFiles = [];
        if (
          remainFreeFiles
          && file.price
          && (section?.freeFileLimit || 0) - (limitCurrentSection?.length || 0) - 1
            >= 0
        ) {
          setIsLimitedFree(true);
          limitedFreeCartFiles.push({ sectionId, file: { ...file, price: '0' } });
        }
        dispatch(
          fetchAddToCart({
            interactions: {
              cartFiles: limitedFreeCartFiles.length ? [] : [{ sectionId, file: file.id }],
              limitedFreeCartFiles:
                limitedFreeCartFiles.length ? [{ sectionId, file: file.id }] : [],
            },
            updateStateData: {
              cartFiles: limitedFreeCartFiles.length ? [] : [{ sectionId, file }],
              limitedFreeCartFiles,
            },
          }),
        );
        setIsInCart(true);
        showElement();
      } else {
        dispatch(fetchRemoveFromCart({
          limitedFreeCartFiles: file.isLimitedFreeFile ? [file] : [],
          cartFiles: file.isLimitedFreeFile ? [] : [file],
        }));
        setIsLimitedFree(false);
        setIsInCart(false);
      }
    },
    [gallery, isInCart, dispatch, remainFreeFiles, freeFiles],
  );

  return {
    isInCart,
    handleClickCart,
    hoverElement: isLimitedFree ? (
      <AfterAddToCartNotification
        remainFreeFiles={remainFreeFiles}
        isVisible={isVisible}
      />
    ) : null,
    isVisible,
    isLimitedFree,
  };
};
