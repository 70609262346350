/* eslint-disable max-len */
import { Collapse, Grid } from '@mui/material';
import { FormikErrors, FormikTouched, useFormikContext } from 'formik';
import React, { ChangeEvent, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CommonSwitch } from '../../../../../../../shared/components/FormElements/CommonSwitch/CommonSwitch';
import { onlyVideosWereSelected } from './helpers';
import { ErrorMessage } from '../../../../../../../shared/components/styled';
import { MiniFileUpload } from '../../../../../../../shared/components/MiniFileUpload/MiniFileUpload';
import { useUploadFiles } from '../../../../../hooks/useUploadFiles';
import { FileRole } from '../../../../../types/gallery';
import { resetFiles, resetErrorFiles, unsetWarning } from '../../../../../redux/gallery/uploadFiles/uploadFilesSlice';
import { getCroppedVideoThumbnail } from '../../../../../../../shared/utils/createGallery/getCroppedImage';
import { CustomAlert } from '../../../../../../../shared/components/CustomAlert/CustomAlert';
import { useCreatorAppDispatch } from '../../../../../../../shared/hooks/useTypedSelector';
import { SetFieldValue } from '../../../../../../../shared/types';
import { GalleryFileSettings } from '../../../../../../../shared/types/commonGallery';
import { CreatorGalleryForm, CreatorGallerySection } from '../../../../../../../shared/types/creatorGallery';

type VideoThumbnailProps = {
  selected: string[];
  values: GalleryFileSettings;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  setFieldValue: SetFieldValue;
  errors: FormikErrors<GalleryFileSettings>;
  touched: FormikTouched<GalleryFileSettings>;
  section: CreatorGallerySection;
  videoId: string;
  disabled?: boolean;
}

export const VideoThumbnail: React.FC<VideoThumbnailProps> = ({
  selected,
  values,
  handleChange,
  errors,
  touched,
  section,
  setFieldValue,
  disabled,
  videoId,
}) => {
  const { t } = useTranslation('createNewGallery');
  const {
    uploading, onUpload, stateFiles, errorFiles, warning,
  } = useUploadFiles();
  const { values: gallery } = useFormikContext<CreatorGalleryForm>();
  const videoFile = section.files?.find((item) => item.id === videoId);
  const dispatch = useCreatorAppDispatch();

  const onlyVideosSelected = useMemo(
    () => onlyVideosWereSelected(section.files, selected),
    [selected, section.files],
  );

  const handleChangeSwitch = (e: ChangeEvent<HTMLInputElement>) => {
    handleChange(e);
    setFieldValue('videoThumbnailFile', '');
  };

  const handleUpload = async (files: File[]) => {
    const videoDoc = document.getElementById(videoFile?.url || '') as HTMLVideoElement;
    const width = videoDoc?.videoWidth || 0;
    const height = videoDoc?.videoHeight || 0;
    let img;
    if (files[0] || videoFile?.videoThumbnailFile) {
      const file = files[0] || videoFile?.videoThumbnailFile;
      const url = window.URL.createObjectURL(file);

      img = await getCroppedVideoThumbnail({
        url,
        id: file.name,
        name: file.name,
        type: file.type,
      }, file?.name, {
        width, height, x: 0, y: 0,
      });
    }
    dispatch(resetFiles());
    dispatch(resetErrorFiles());
    if (img) {
      onUpload({
        files: [img],
        galleryId: gallery.id,
        fileRole: FileRole.VideoCover,
        anchorFileId: selected[0],
      });
    }
  };

  const error = errors.videoThumbnailFile && touched.videoThumbnailFile ? errors.videoThumbnailFile : null;
  const errorText = error === 'Full' ? 'Storage is full' : (error || errors.videoThumbnailFile);

  useEffect(() => {
    if (stateFiles.length) {
      setFieldValue('videoThumbnailFile', stateFiles[0]);
    }
  }, [stateFiles]);

  if (!onlyVideosSelected) {
    return null;
  }

  return (
    <Grid item sm={12}>
      <CommonSwitch
        isChecked={!!values.hasVideoThumbnail}
        handleChange={handleChangeSwitch}
        name="hasVideoThumbnail"
        label={t('thirdStep.uploadThumbnail')}
        disabled={selected?.length === 0 || disabled}
        withoutMargins
        helpText={t('thirdStep.helpTexts.videoThumbnail')}
        spaceBetween
      />
      <div style={{ marginTop: error ? '-18px' : '' }}>
        <Collapse in={!!values.hasVideoThumbnail}>
          <ErrorMessage>
            {errorFiles.length ? 'File wasn\'t uploaded' : null}
          </ErrorMessage>
          <MiniFileUpload
            handleUpload={handleUpload}
            disabled={disabled}
            value={values.videoThumbnailFile?.url || ''}
            setFieldValue={setFieldValue}
            name="videoThumbnailFile"
            error={errorText}
            touched={touched.videoThumbnailFile}
            isLoading={!!uploading.length}
          />
        </Collapse>
      </div>
      <CustomAlert
        type="warning"
        message={warning}
        title="The file wasn't uploaded"
        isOpenWindow={!!warning}
        onClose={() => dispatch(unsetWarning())}
      />
    </Grid>
  );
};
