import { useMemo } from 'react';
import { useTypedSelectorClient } from '../../../shared/hooks/useTypedSelector';
import { selectClientLimitedFreeCartFiles } from '../../Client/redux/interactions/interactionsClientSelectors';

export const useGetFreeFilesRemaning = (sectionId: string, sectionFreeFiles: number) => {
  const { sections } = useTypedSelectorClient(
    (state) => selectClientLimitedFreeCartFiles(
      state.interactionsClient?.interaction?.limitedFreeCartFiles,
    ),
  );
  const remainFreeFiles = useMemo(() => {
    let local = sectionFreeFiles;
    sections.forEach((item) => {
      if (item.sectionId === sectionId) {
        local -= 1;
      }
    });
    return local;
  }, [sections, sectionId, sectionFreeFiles]);

  return remainFreeFiles;
};
