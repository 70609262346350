import React, {
  ReactElement, useMemo, useState,
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch } from 'react-redux';
import { ClientGalleryGet, CustomFile, GalleryComment } from '../../../../shared/types';
import { CommentAreaWindow } from '../../components/CommentArea/CommentAreaWindow';
import { FileAndCommentsWindowContainer } from '../../components/CommentArea/FileAndCommentsWindowContainer';
import { getFormatDateForDob } from '../../../../shared/utils';
import { fetchAddComment, fetchDeleteComment } from '../../redux/comments/commentsThunk';
import { getSectionId } from '../../utils/helpers';
import { useTypedSelectorClient } from '../../../../shared/hooks/useTypedSelector';

type ReturnObj = {
  handleOpenComments: () => void;
  commentArea: ReactElement | null;
  isCommentsOpen: boolean;
  numberOfComments: number;
  addComment: (comment: string, resetForm: () => void) => void;
  removeComment: (commentId: string) => void;
};

type UseCommentProps = {
  isFileOpen?: boolean;
  file?: CustomFile | null;
  comments?: GalleryComment[];
  fileId: string;
  gallery: ClientGalleryGet | null;
};

// use only on client side
export const useComment = ({
  isFileOpen,
  file,
  fileId,
  gallery,
}: UseCommentProps): ReturnObj => {
  const [isCommentsOpen, setIsCommentOpen] = useState(false);
  const dispatch = useDispatch<any>();
  const interaction = useTypedSelectorClient((state) => state.interactionsClient?.interaction);

  const comments: GalleryComment[] = useMemo(() => {
    let res: GalleryComment[] = [];
    // eslint-disable-next-line max-len
    gallery?.sections?.forEach((section) => [...(section?.files || []), ...(section?.ideas || [])]?.forEach((item) => {
      if (item.id === fileId) {
        res = item.comments || [];
      }
    }));
    return res;
  }, [gallery]);

  const sectionId: string = useMemo(
    () => getSectionId(gallery, fileId),
    [gallery, fileId, getSectionId],
  );

  const handleOpenComments = () => {
    setIsCommentOpen(true);
  };
  const handleCloseComments = () => {
    setIsCommentOpen(false);
  };

  const addComment = async (comment: string, resetForm: () => void) => {
    if (gallery) {
      const res = await dispatch(
        fetchAddComment({
          _id: interaction._id,
          galleryId: interaction.galleryId,
          email: interaction.email,
          sectionId,
          fileId,
          comment: {
            text: comment,
            id: uuidv4(),
            date: getFormatDateForDob(new Date()),
            creatorMessage: false,
          },
        }),
      );
      if (!res?.error) {
        resetForm();
      }
    }
  };

  const removeComment = (commentId: string) => {
    if (gallery) {
      dispatch(
        fetchDeleteComment({
          commentId,
          fileId,
          sectionId,
        }),
      );
    }
  };

  let commentArea = null;
  if (isCommentsOpen) {
    if (isFileOpen) {
      commentArea = (
        <FileAndCommentsWindowContainer
          isOpen={isCommentsOpen}
          handleClose={handleCloseComments}
          file={file}
          comments={comments || []}
          dateFormat={gallery?.dateFormat || ''}
          addComment={addComment}
          deleteComment={removeComment}
        />
      );
    } else {
      commentArea = (
        <CommentAreaWindow
          comments={comments || []}
          handleClose={handleCloseComments}
          isOpen={isCommentsOpen}
          dateFormat={gallery?.dateFormat || ''}
          addComment={addComment}
          deleteComment={removeComment}
        />
      );
    }
  }

  return {
    handleOpenComments,
    commentArea,
    isCommentsOpen,
    numberOfComments: comments?.length || 0,
    addComment,
    removeComment,
  };
};
