/* eslint-disable */
import { Grid } from '@mui/material';
import { useFormikContext } from 'formik';
import React, { ChangeEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CommonFileUpload } from '../../../../../../../shared/components/FormElements/CommonFileUpload/CommonFileUpload';
import { deleteSizesFromLocalStorage } from '../../../../../../../shared/utils/createGallery/deleteSizesFromLocalStorage';
import { useUploadFiles } from '../../../../../hooks/useUploadFiles';
import { resetFiles, unsetError, unsetWarning } from '../../../../../redux/gallery/uploadFiles/uploadFilesSlice';
import { FileRole } from '../../../../../types/gallery';
import { CustomAlert } from '../../../../../../../shared/components/CustomAlert/CustomAlert';
import { useCreatorAppDispatch } from '../../../../../../../shared/hooks/useTypedSelector';
import { CreatorGalleryForm } from '../../../../../../../shared/types/creatorGallery';

export const FirstStepCoverUpload: React.FC = () => {
  const { t } = useTranslation('createNewGallery');
  const dispatch = useCreatorAppDispatch();

  const { uploading, onUpload, stateFiles, error, warning, errorFiles } = useUploadFiles();
  const { values, errors, touched, setFieldValue, setFieldTouched } =
    useFormikContext<CreatorGalleryForm>();
  useEffect(() => {
    if (error) {
      setFieldTouched('coverFile.original', true);
    }
  }, [error]);

  useEffect(() => {
    if (stateFiles.length) {
      if (!stateFiles[0].name.includes('.desktop.') && !stateFiles[0].name.includes('.mobile.') && !stateFiles[0].name.includes('.tablet.')) {
        setFieldValue('coverFile.original', stateFiles[0]);
        setFieldValue('coverFile.desktop', null);
        setFieldValue('coverFile.tablet', null);
        setFieldValue('coverFile.mobile', null);
        deleteSizesFromLocalStorage(['desktop', 'tablet', 'mobile']);
        dispatch(resetFiles());  
      }
    }
  }, [stateFiles]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    setFieldValue('coverFile.original', null);
    setFieldValue('coverFile.desktop', null);
    setFieldValue('coverFile.tablet', null);
    setFieldValue('coverFile.mobile', null);
    deleteSizesFromLocalStorage(['desktop', 'tablet', 'mobile']);
    dispatch(resetFiles());

    onUpload({
      files: Array.from(e.currentTarget.files ?? []), 
      galleryId: values.id, 
      fileRole: FileRole.GalleryCover
    });
  };

  const uploadingError = values.coverFile.original?.url ? null : error;
  const errorText = uploadingError || (errors.coverFile?.original as any)?.id || error?.length;

  return (
    <>
      <Grid container item sm={6}>
        <Grid item xl={8} lg={9} md={12}>
          <CommonFileUpload
            id="coverFileFirstStep"
            name="coverFile"
            handleChange={handleChange}
            touched={touched.coverFile?.original}
            label={t('firstStep.choosePhotoVideo')}
            value={values?.coverFile?.original}
            error={errorText}
            accept="image/*"
            isLoading={!!uploading.length}
            // isLoadingWithProgress={!!uploading.length}
          />
        </Grid>
      </Grid>
      <div style={{ position: 'absolute' }}>
        <CustomAlert
          type="warning"
          message={warning}
          title="The file wasn't uploaded"
          isOpenWindow={!!warning}
          onClose={() => dispatch(unsetWarning())}
        />
      </div>
    </>
  );
};
