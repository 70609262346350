import { FormikHelpers, useFormikContext } from 'formik';
import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { errorMessages, isVideo } from '../../../../../../../shared/utils';
import { useErrors } from '../../../../../hooks/useErrors';
import { getFileObjectForUpdateRequest } from '../../../../../utils/getGalleryDataForRequest';
import { setSuccessUpdateFalse } from '../../../../../redux/gallery/singleGallery/singleGalleryCreatorSlice';
import { unsetIsSuccess, unsetValues } from '../../../../../redux/gallery/fileSettings/fileSettingsCreatorSlice';
import { useCreatorAppDispatch } from '../../../../../../../shared/hooks/useTypedSelector';
import { useUpdateFiles } from './useUpdateFiles';
import {
  CreatorGalleryFile, GalleryFileBaseWithUrls, GalleryFileSettings, GalleryIdea,
} from '../../../../../../../shared/types/commonGallery';
import { CreatorGalleryForm, CreatorGallerySection } from '../../../../../../../shared/types/creatorGallery';

type ReturnObj = {
  formError: string;
  setFormError: Dispatch<SetStateAction<string>>;
  handleApplySettings: (
    data: GalleryFileSettings,
    bag: FormikHelpers<GalleryFileSettings>,
    currentSection: CreatorGallerySection,
    selectedIds: string[],
  ) => void;
  applySettings: (
    currentSection: CreatorGallerySection,
    settings: GalleryFileSettings,
  ) => { resFiles: CreatorGalleryFile[], resIdeas: GalleryIdea[] };
  settingsApplied: boolean;
  isLoading: boolean;
  errorAlert: ReactElement;
  disabled: boolean;
};

type ApplyFileSettingsProps = {
  isItemSelected: (id: string) => boolean;
};

export const useApplyFileSettings = ({
  isItemSelected,
}: ApplyFileSettingsProps): ReturnObj => {
  const [formError, setFormError] = useState<string>('');
  const [settingsApplied, setSettingsApplied] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [bagObj, setBagObj] = useState<FormikHelpers<GalleryFileSettings> | null>(null);
  const { values: gallery, setFieldValue } = useFormikContext<CreatorGalleryForm>();
  const dataCopy: CreatorGalleryForm = JSON.parse(JSON.stringify(gallery));
  const {
    updateFiles, isLoading, error, isSuccess,
  } = useUpdateFiles();
  const dispatch = useCreatorAppDispatch();
  const errorAlert = useErrors(error, bagObj?.setFieldError);

  const applySettings = (
    currentSection: CreatorGallerySection,
    settings: GalleryFileSettings,
  ): { resFiles: CreatorGalleryFile[], resIdeas: GalleryIdea[] } => {
    try {
      const indexSection = dataCopy.sections?.findIndex(
        (item) => item.id === currentSection.id,
      );
      const resFiles = currentSection?.files?.map((item) => {
        if (isItemSelected(item.id)) {
          if (isVideo(item)) {
            item = {
              ...item,
              ...settings,
              videoThumbnailFile: {
                ...settings.videoThumbnailFile,
                id: (settings.videoThumbnailFile as unknown as any)?.thumbnail || '',
              } as GalleryFileBaseWithUrls,
            };
          } else {
            item = { ...item, ...settings };
          }
        }
        return item;
      });
      const resIdeas = currentSection?.ideas.map((item) => {
        if (isItemSelected(item.id)) {
          item = {
            ...item,
            // instantlyDownloadable: data.instantlyDownloadable,
            watermark: settings.watermark,
            allowCommenting: settings.allowCommenting,
          };
        }
        return item;
      });
      setFieldValue(`sections[${indexSection}].files`, resFiles);
      setFieldValue(`sections[${indexSection}].ideas`, resIdeas);

      return { resFiles, resIdeas };
    } catch (e) {
      throw new Error('Something went wrong');
    }
  };

  const handleApplySettings = async (
    data: GalleryFileSettings,
    bag: FormikHelpers<GalleryFileSettings>,
    currentSection: CreatorGallerySection,
    selectedIds: string[],
  ) => {
    setBagObj(bag);
    setDisabled(true);
    try {
      applySettings(currentSection, data);
      const resFiles = getFileObjectForUpdateRequest(selectedIds, data);
      await updateFiles(resFiles);

      setFormError('');
      dispatch(unsetValues({ sectionId: currentSection.id }));
    } catch (err: any) {
      setFormError(errorMessages.smthWentWrong);
      setDisabled(false);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      setSettingsApplied(true);
      setTimeout(() => {
        setDisabled(false);
        setSettingsApplied(false);
      }, 1000);
      dispatch(setSuccessUpdateFalse());
      dispatch(unsetIsSuccess());
    }
  }, [isSuccess]);

  return {
    formError,
    setFormError,
    handleApplySettings,
    applySettings,
    settingsApplied,
    isLoading,
    errorAlert,
    disabled,
  };
};
