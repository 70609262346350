/* eslint-disable react/jsx-no-useless-fragment */
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth, useUser } from '@clerk/clerk-react';
import { v4 as uuidv4 } from 'uuid';
import { useCreatorAppDispatch, useTypedSelectorCreator } from '../../../../../../../../../shared/hooks/useTypedSelector';
import {
  MediumGreyText,
  SimpleBlackText,
  WhiteButtonWithoutShadow,
} from '../../../../../../../../../shared/styles';
import { getFormatDateForDob, getFormattedDateOrDateName, isIdea } from '../../../../../../../../../shared/utils';
import { FileAndCommentsWindow } from '../../../../../../../../GalleryView/components/CommentArea/FileAndCommentsWindow';
import {
  CommentItemContainer,
  CommentItemInfoWrapper,
  CommentItemFileWrapper,
  CommentItemReplyButton,
  CommentItemMessage,
} from './styled';
import { getFileInContainer } from '../../../../../../../../../shared/utils/createGallery';
import { getAllFiles } from '../../../../../../../../../shared/utils/gallery/getAllFiles';
import { CreatorGalleryFile, GalleryComment, GalleryIdea } from '../../../../../../../../../shared/types/commonGallery';
import { getSectionId } from '../../../../../../../../GalleryView/utils/helpers';
import { CreatorCommentGet } from '../../../../../../../../../shared/types/client';
import { fetchCreatorAddComment, fetchCreatorDeleteComment } from '../../../../../../../redux/gallery/interactions/interactionsCreatorThunk';
import { selectGallery } from '../../../../../../../redux/gallery/allGaleries/galleryCreatorSlice';

type CommentsItemProps = {
  comment: CreatorCommentGet;
  galleryId: string;
};

export const CommentsItem: React.FC<CommentsItemProps> = ({ comment, galleryId }) => {
  const { t } = useTranslation('basicComponents');
  const [isOpenComment, setIsOpenComment] = useState(false);
  const timezone = useTypedSelectorCreator((state) => state.creator?.currentUser?.timezone);
  const dateFormat = useTypedSelectorCreator((state) => state.creator?.currentUser?.dateFormat) || 'mm/dd/yyyy';
  const comments = useTypedSelectorCreator((state) => state?.interactions?.comments);
  const gallery = useTypedSelectorCreator((state) => selectGallery(state.galleries, galleryId));
  const dispatch = useCreatorAppDispatch();
  const { user } = useUser();
  const { getToken } = useAuth();

  const addComment = async (text: string) => {
    if (gallery) {
      const sectionId = getSectionId(gallery, comment.fileId);
      await dispatch(
        fetchCreatorAddComment({
          galleryId: gallery.id,
          getToken,
          email: comment.email,
          _id: comment.interactionId,
          sectionId,
          fileId: comment.fileId,
          comment: {
            id: uuidv4(),
            text,
            date: getFormatDateForDob(new Date()),
            creatorMessage: true,
          },
        }),
      );
    }
  };
  const deleteComment = (commentId: string) => {
    if (gallery) {
      const sectionId = getSectionId(gallery, comment.fileId);

      dispatch(
        fetchCreatorDeleteComment({
          galleryId: gallery.id,
          getToken,
          email: comment.email,
          _id: comment.interactionId,
          commentId,
          fileId: comment.fileId,
          sectionId,
        }),
      );
    }
  };

  const handleClose = () => {
    setIsOpenComment(false);
  };
  const handleOpen = () => {
    setIsOpenComment(true);
  };

  const file = useMemo(() => {
    const files = getAllFiles(gallery);
    const res = files.find((state) => state.id === comment.fileId);
    return res;
  }, [gallery, comment.fileId]);

  const formattedDate = getFormattedDateOrDateName(comment.date, dateFormat, timezone, true);
  const fileItem = isIdea(file) ? (file as GalleryIdea).cover : (file as CreatorGalleryFile);
  const fileInContainer = getFileInContainer({
    file: fileItem,
  });
  const creatorsComments = useMemo(() => {
    const res: GalleryComment[] = [];
    comments.forEach((item) => {
      item.messages.forEach((itemSecond) => {
        if (itemSecond.fileId === comment.fileId
          && item.email === comment.email
          && item.interactionId === comment.interactionId
        ) {
          itemSecond.messages.forEach((itemThird) => {
            if (itemThird.creatorMessage) {
              res.push({
                text: itemThird.text,
                id: itemThird.id,
                date: itemThird.date,
                creatorMessage: true,
              });
            }
          });
        }
      });
    });
    return res;
  }, [comments, comment]);

  const allComments = useMemo(() => [{
    text: comment?.message,
    id: comment?.id,
    date: comment?.date,
    creatorMessage: false,
  }, ...creatorsComments].sort(
    (item1, item2) => (new Date(item2?.date || '') as any)
        - (new Date(item1?.date || '') as any),
  ), [creatorsComments, comment]);

  return (
    <>
      <CommentItemContainer>
        <CommentItemInfoWrapper>
          <SimpleBlackText fontWeight={600}>{comment?.email || ''}</SimpleBlackText>
          <CommentItemMessage>{comment?.message}</CommentItemMessage>
          <MediumGreyText>{formattedDate}</MediumGreyText>
        </CommentItemInfoWrapper>
        <CommentItemFileWrapper>
          <CommentItemReplyButton>
            <WhiteButtonWithoutShadow type="button" onClick={handleOpen}>
              {t('buttons.reply')}
            </WhiteButtonWithoutShadow>
          </CommentItemReplyButton>
          {fileInContainer}
        </CommentItemFileWrapper>
      </CommentItemContainer>
      <FileAndCommentsWindow
        addComment={addComment}
        deleteComment={deleteComment}
        isOpen={isOpenComment}
        userProfileUrl={user?.hasImage ? user.imageUrl : ''}
        handleClose={handleClose}
        comments={allComments}
        file={fileItem}
        dateFormat={dateFormat}
      />
    </>
  );
};
