import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ErrorType } from '../../../../../shared/types';
import { AllSalesGet } from '../../../types/sales';
import { fetchGetAllSales } from './allSalesThunk';

function daysInMonth() {
  const monthToday = new Date().getMonth();
  const yearToday = new Date().getFullYear();

  return new Date(yearToday, monthToday, 0).getDate();
}

type InitialState = {
  loading: boolean;
  error: ErrorType;
  data: AllSalesGet;
};

export const initialState: InitialState = {
  data: {
    prev1: new Array(24).fill(0),
    prev7: new Array(7).fill(0),
    prev31: new Array(31).fill(0),
    countPrev31: 0,
    totalPrev31: 0,
    netRevenueAllTime: 0,
  },
  loading: false,
  error: null,
};

const allSalesSlice = createSlice({
  name: 'allSales',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchGetAllSales.fulfilled,
      (state, action: PayloadAction<AllSalesGet>) => {
        state.error = null;
        state.loading = false;
        state.data = action.payload;
      },
    );
    builder.addCase(
      fetchGetAllSales.rejected,
      (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loading = false;
      },
    );
    builder.addCase(fetchGetAllSales.pending, (state) => {
      state.loading = true;
    });
  },
});

export default allSalesSlice.reducer;
