import styled from '@emotion/styled';
import { SimpleBlackText } from '../../../../../../../../../shared/styles';
import { ActiveGalleryFileWrapper, GalleryDetailsTabContent } from '../styled';

export const CommentItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 116px;
  flex-wrap: nowrap;
  padding-bottom: 30px;
`;

export const CommentItemFileWrapper = styled(ActiveGalleryFileWrapper)` 
  &:hover {
    & > div {
      display: flex;
    }
  }

  & img {
    vertical-align: middle;
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
`;

export const CommentItemReplyButton = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #00000045;
  display: none;
  justify-content: center;
  align-items: center;

  & > * {
    padding: 9px 18px;
  }
`;

export const CommentItemInfoWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: auto;
  margin-right: 30px;

  & div,
  span {
    &:not(:last-child) {
      margin-bottom: 14px;
    }
  }
`;

export const CommentItemMessage = styled(SimpleBlackText)`
  display: flex;
  display: -moz-box;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -moz-box-orient: vertical;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-clamp: 2;
  margin: 0;
`;

export const CommentsOrIdeasListWrapper = styled(GalleryDetailsTabContent)`
  padding-right: 10px;
`;
