import React, { useContext, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Zoom } from '@mui/material';
import { ChangeColorButton, GradientCover, IconsWrapper } from './styled';
import {
  CartIcon,
  HeartIcon,
  InstantDownload,
  CommentIcon,
  HeartFilledIcon,
} from '../../icons/FileIcons';
import { useLike } from '../../../modules/GalleryView/hooks/buttons/useLike';
import { useAddToCart } from '../../../modules/GalleryView/hooks/buttons/useAddToCart';
import { ClientGalleryFile, GalleryComment } from '../../types';
import { useComment } from '../../../modules/GalleryView/hooks/buttons/useComment';
import { ClientOnboardingContext } from '../../../modules/Client/context/CreatorOnboardingContext';
import { useFreeFilesDownload } from '../../../modules/GalleryView/hooks/buttons/useDownload';
import { useCheckWindowSize } from '../../hooks/useCheckWindowSize';
import {
  useTypedSelectorClient,
  useTypedSelectorGallery,
} from '../../hooks/useTypedSelector';
import { PinkLoader } from '../Loaders/PinkLoader/PinkLoader';
import { selectClientCart, selectClientFavourites, selectClientLimitedFreeCartFiles } from '../../../modules/Client/redux/interactions/interactionsClientSelectors';

type GradientCoverWithActionsProps = {
  isInstantlyDownloadable: boolean;
  isAllowCommenting: boolean;
  disabledAll?: boolean;
  id: string;
  sectionId?: string;
  isFileLiked?: boolean;
  isFileInCart?: boolean;
  file: ClientGalleryFile | null;
  isGradientVisible?: boolean;
  comments?: GalleryComment[];
  isLimitedFreeFile: boolean;
};

export const GradientCoverWithActions: React.FC<
  GradientCoverWithActionsProps
> = ({
  isInstantlyDownloadable,
  isAllowCommenting,
  disabledAll,
  id,
  sectionId,
  isFileLiked,
  isFileInCart,
  isLimitedFreeFile,
  file,
  isGradientVisible,
  comments,
}) => {
  const { isLiked, handleLike } = useLike(!!isFileLiked);
  const {
    handleDownloadSingleFile: handleDownload,
    downloadErrorAlert,
    isLoadingFile,
  } = useFreeFilesDownload();
  const {
    isInCart, handleClickCart, hoverElement, isLimitedFree,
  } = useAddToCart(
    !!isFileInCart,
    sectionId || '',
    !!isLimitedFreeFile,
  );
  const gallery = useTypedSelectorGallery((state) => state?.gallery?.gallery);

  const { sections: cartSections } = useTypedSelectorClient(
    (state) => selectClientCart(state?.interactionsClient?.interaction?.cartFiles),
  );
  const { sections: freeLimitedFiles } = useTypedSelectorClient(
    (state) => selectClientLimitedFreeCartFiles(
      state?.interactionsClient?.interaction?.limitedFreeCartFiles,
    ),
  );
  const { files: favFiles } = useTypedSelectorClient(
    (state) => selectClientFavourites(state?.interactionsClient?.interaction?.favourites),
  );

  const isInCartLocal = useMemo(
    () => !!cartSections
      ?.find((item) => item.sectionId === sectionId && item.file.id === id),
    [cartSections, id, sectionId],
  );
  const isFreeLimitedCartLocal = useMemo(
    () => !!freeLimitedFiles
      ?.find((item) => item.sectionId === sectionId && item.file.id === id),
    [freeLimitedFiles, id, sectionId],
  );
  const isLikedLocal = useMemo(
    () => !!favFiles?.find((item) => item.id === id),
    [favFiles, id],
  );

  const { handleOpenComments, commentArea } = useComment({
    isFileOpen: true,
    file,
    comments,
    fileId: id,
    gallery,
  });
  const { isStudy: isStudyClient } = useContext(ClientOnboardingContext);
  const { screenSize } = useCheckWindowSize();

  if (screenSize !== 'desktop') {
    return null;
  }

  return (
    <>
      <GradientCover
        id="cover-gradient"
        isStudy
        isGradientVisible={!!isGradientVisible}
      >
        <IconsWrapper id="client-buttons-over-item-step">
          {(isInstantlyDownloadable || isStudyClient) && (
            <button
              disabled={!!disabledAll}
              type="button"
              id="client-instantly-downloadable-step"
              onClick={() => handleDownload(id, file?.name || '', gallery?.id || '', sectionId || '')}
            >
              {isLoadingFile.includes(id) ? (
                <PinkLoader size="xs" />
              ) : (
                <InstantDownload />
              )}
            </button>
          )}
          <ChangeColorButton
            isClicked={isLiked || isLikedLocal}
            disabled={!!disabledAll}
            type="button"
            onClick={() => handleLike(file!)}
            id="client-like-button-step"
          >
            {(isLiked || isLikedLocal) && (
              <Zoom in={isLiked || isLikedLocal}>
                <div style={{ display: 'flex' }}>
                  <HeartFilledIcon />
                </div>
              </Zoom>
            )}
            {!(isLiked || isLikedLocal) && (
              <Zoom in={!(isLiked || isLikedLocal)}>
                <div style={{ display: 'flex' }}>
                  <HeartIcon />
                </div>
              </Zoom>
            )}
          </ChangeColorButton>
          <ChangeColorButton
            isClicked={isInCart || isInCartLocal || isLimitedFree || isFreeLimitedCartLocal}
            disabled={!!disabledAll}
            type="button"
            onClick={() => handleClickCart(file as ClientGalleryFile)}
            style={{ position: 'relative' }}
            id="client-download-cart-button-step"
          >
            {hoverElement}
            {(isInCart || isInCartLocal) && (
              <Zoom in={isInCart || isInCartLocal}>
                <div style={{ display: 'flex' }}>
                  <CartIcon />
                </div>
              </Zoom>
            )}
            {!(isInCart || isInCartLocal) && (
              <Zoom in={!(isInCart || isInCartLocal)}>
                <div style={{ display: 'flex' }}>
                  <CartIcon />
                </div>
              </Zoom>
            )}
          </ChangeColorButton>
          {(isAllowCommenting || isStudyClient) && (
            <button
              aria-label="comment"
              disabled={!!disabledAll}
              type="button"
              style={{ position: 'relative' }}
              onClick={handleOpenComments}
              id="client-comment-button-step"
            >
              <CommentIcon />
            </button>
          )}
        </IconsWrapper>
      </GradientCover>
      {!disabledAll && commentArea}
      {downloadErrorAlert}
    </>
  );
};
