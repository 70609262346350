import { createAsyncThunk } from '@reduxjs/toolkit';
import { CommentPutReq } from '../../../../shared/types/client';
import {
  addCommentHandle as addCommentClient,
  deleteCommentHandle as delCommentClient,
} from '../../../Client/redux/signIn/signInClientSlice';
import {
  deleteCommentHandle,
} from '../gallery/gallerySlice';
import { clientInteractions } from '../../../../api/client/interactions';
import { GalleryComment } from '../../../../shared/types';

export const fetchAddComment = createAsyncThunk(
  'comments/fetchAddComment',
  async (
    data: {
      comment: GalleryComment;
      sectionId: string;
      fileId: string;
      _id: string;
      galleryId: string;
      email: string;
    },
    thunkApi,
  ) => {
    try {
      const response = await clientInteractions.modifyInteractions(data._id, {
        comments: { sectionId: data.sectionId, fileId: data.fileId, message: data.comment },
        galleryId: data.galleryId,
        email: data.email,
        id: data._id,
      });

      const commentClientData: CommentPutReq = {
        galleryId: data.galleryId,
        sectionId: data.sectionId,
        fileId: data.fileId,
        messageId: data.comment.id,
        text: data.comment.text,
        date: data.comment.date,
      };
      thunkApi.dispatch(addCommentClient(commentClientData));
      // thunkApi.dispatch(addCommentCreatorHandle(commentClientData));
      return data.comment;
    } catch (err: any) {
      console.log({ err });
      return thunkApi.rejectWithValue('Failed to add the comment');
    }
  },
);

export const fetchDeleteComment = createAsyncThunk(
  'comments/fetchDeleteComment',
  async (
    data: { commentId: string; sectionId: string; fileId: string },
    thunkApi,
  ) => {
    try {
      const state: any = thunkApi.getState() as any;
      const { _id, galleryId, email } = state.interactionsClient.interaction;
      const response = await clientInteractions.deleteInteractionItem(_id, {
        comments: {
          sectionId: data.sectionId,
          fileId: data.fileId,
          commentId: data.commentId,
        },
        galleryId,
        email,
      });
      thunkApi.dispatch(
        delCommentClient({ commentId: data.commentId, fileId: data.fileId }),
      );
      thunkApi.dispatch(
        deleteCommentHandle({ commentId: data.commentId, fileId: data.fileId }),
      );

      return data.commentId;
    } catch (err: any) {
      console.log({ err });
      return thunkApi.rejectWithValue('Failed to delete the comment');
    }
  },
);
