import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TableBody, TableContainer, TableHead, TableRow,
} from '@mui/material';
import { useAuth } from '@clerk/clerk-react';
import { SimpleBlackText } from '../../../../../../../../../shared/styles';
import { PinkLoader } from '../../../../../../../../../shared/components/Loaders/PinkLoader/PinkLoader';
import { OrderRow } from './OrderRow';
import { Order } from '../../../../../../../types/sales';
import {
  StyledTable, StyledTableCell, tableContainerStyle, StyledTablePagination,
} from '../../../../../../../../../shared/components/StyledTable/StyledTable';
import { OrdersTableLoaderWrapper } from '../../../../Sales/styled';
import { useCreatorAppDispatch, useTypedSelectorCreator } from '../../../../../../../../../shared/hooks/useTypedSelector';
import { fetchGetOrdersByGalleryId } from '../../../../../../../redux/orders/ordersThunk';
import { usePagination } from '../../../../../../../../../shared/hooks/usePagination';

type OrdersProps = {
  orders: Order[];
  galleryName: string;
  galleryId: string;
};

export const OrdersTab: React.FC<OrdersProps> = ({ orders, galleryName, galleryId }) => {
  const { t } = useTranslation('gallery');
  const isLoading = false;
  const { getToken } = useAuth();
  const dispatch = useCreatorAppDispatch();
  const {
    handleChangePage, handleChangeRowsPerPage, page, rowsPerPage,
  } = usePagination();
  const { total } = useTypedSelectorCreator((state) => state.orders);

  useEffect(() => {
    dispatch(fetchGetOrdersByGalleryId({
      getToken, limit: rowsPerPage, page: page + 1, galleryId,
    }));
  }, [page, rowsPerPage]);

  if (!orders?.length) return <SimpleBlackText>{t('noOrders')}</SimpleBlackText>;

  return (
    <div>
      <TableContainer sx={{
        maxHeight: '200px',
        ...tableContainerStyle,
      }}
      >
        <StyledTable stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{ whiteSpace: 'pre', paddingLeft: '0px', paddingTop: '0px' }} align="left">{t('ordersTab.clientEmail')}</StyledTableCell>
              <StyledTableCell sx={{ paddingTop: '0px' }} align="left">{t('ordersTab.date')}</StyledTableCell>
              <StyledTableCell sx={{ paddingTop: '0px' }} align="left">{t('ordersTab.time')}</StyledTableCell>
              <StyledTableCell sx={{ paddingTop: '0px' }} align="left">{t('ordersTab.status')}</StyledTableCell>
              <StyledTableCell sx={{ whiteSpace: 'pre', paddingTop: '0px' }} align="left">{t('ordersTab.cartTotal')}</StyledTableCell>
              <StyledTableCell sx={{ paddingTop: '0px' }} align="center">Details</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!isLoading && (
              orders.map((item) => (
                <OrderRow
                  key={item.id}
                  row={item}
                  galleryName={galleryName}
                />
              ))
            )}
          </TableBody>
        </StyledTable>
        {isLoading && (
        <OrdersTableLoaderWrapper>
          <PinkLoader size="xs" />
        </OrdersTableLoaderWrapper>
        )}
      </TableContainer>
      <StyledTablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={total}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPage={rowsPerPage}
      />
    </div>
  );
};
