import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { FormGalleryWrapper } from '../styled';
import { Section } from './Section';
import { ButtonAdd } from './ButtonAdd';
import { resetUploadingFiles, resetErrorFiles, unsetError } from '../../../../../redux/gallery/uploadFiles/uploadFilesSlice';
import { useCreatorAppDispatch } from '../../../../../../../shared/hooks/useTypedSelector';
import { CreatorGalleryForm } from '../../../../../../../shared/types/creatorGallery';

export const NewGalleryCreatorSecondStep: React.FC = () => {
  const { t } = useTranslation('createNewGallery');
  const { values, setValues } = useFormikContext<CreatorGalleryForm>();
  const dispatch = useCreatorAppDispatch();

  const handleClick = () => {
    const data = values;
    const sectionNumber = (values?.sections?.length || 0) + 1;
    data?.sections?.push({
      id: uuidv4(),
      title: `Section ${sectionNumber}`,
      files: [],
      ideas: [],
      hasFreeFiles: false,
      freeFileLimit: 0,
    });
    setValues(data);
  };

  useEffect(() => () => {
    dispatch(resetUploadingFiles());
    dispatch(resetErrorFiles());
    dispatch(unsetError());
  }, []);

  return (
    <>
      <FormGalleryWrapper>
        {values?.sections?.map((item) => (
          <Section key={item.id} section={item} />
        ))}
      </FormGalleryWrapper>
      <ButtonAdd
        id="addSectionSecondStep"
        handleClick={handleClick}
        size="large"
        text={t('secondStep.addSection')}
      />
    </>
  );
};
