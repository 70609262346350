import React, { useContext, useEffect } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import { useBeforeUnload, useBlocker, useNavigate } from 'react-router-dom';
import { ProgressBar } from './ProgressBar';
import { getNewGallerySchema } from '../../../../../../shared/validation/gallery/newGallerySchema';
import { StepSwitcher } from './StepSwitcher/StepSwitcher';
import { NewGalleryCreatorButtons } from './NewGalleryCreatorButtons/NewGalleryCreatorButtons';
import { CreatorOnboardingContext } from '../../../../context/CreatorOnboardingContext';
import { PinkLoader } from '../../../../../../shared/components/Loaders/PinkLoader/PinkLoader';
import { CoverWindow } from '../../../../../../shared/components/LoadingForRequest/styled';
import { useSubmitGalleryObj } from './hooks/useSubmitGalleryObj';
import { RouteLeavingGuard } from './RouteLeavingGuard/RouteLeavingGuard';
import { useCreatorAppDispatch } from '../../../../../../shared/hooks/useTypedSelector';
import { resetFiles } from '../../../../redux/gallery/uploadFiles/uploadFilesSlice';
import { ErrorMessage } from '../../../../../../shared/components/styled';
import { CreatorGalleryForm } from '../../../../../../shared/types/creatorGallery';

type NewGalleryCreatorProps = {
  galleryId: string;
  currentGallery: CreatorGalleryForm;
};

export const NewGalleryCreator: React.FC<NewGalleryCreatorProps> = ({
  galleryId,
  currentGallery,
}) => {
  const { isStudy } = useContext(CreatorOnboardingContext);
  const navigate = useNavigate();
  const dispatch = useCreatorAppDispatch();
  dispatch(resetFiles());

  const {
    onSubmit, step, errorAlert, isLoading, errorUpdate, clickSteps, confirmAlert,
  } = useSubmitGalleryObj();

  const validationSchema = isStudy ? null : getNewGallerySchema(step);

  return (
    <>
      <Formik<CreatorGalleryForm>
        initialValues={currentGallery}
        onSubmit={(
          data: CreatorGalleryForm,
          bag: FormikHelpers<CreatorGalleryForm>,
        ) => onSubmit(data, bag)}
        validationSchema={validationSchema}
      >
        <>
          <Form>
            <ProgressBar clickStep={clickSteps} step={step} />
            {errorUpdate && (
              <ErrorMessage
                style={{ fontSize: '14px', marginBottom: '10px', display: 'block' }}
              >
                  {errorUpdate}
              </ErrorMessage>
            )}
            <StepSwitcher step={step} />
            <NewGalleryCreatorButtons clickSteps={clickSteps} step={step} />
          </Form>
          {!isStudy
          && (
          <RouteLeavingGuard
            navigate={(path) => {
              navigate(path);
            }}
            id={galleryId}
          />
          )}
        </>
      </Formik>
      {isLoading && (
        <CoverWindow>
          <PinkLoader color="pink" />
        </CoverWindow>
      )}
      {errorAlert}
      {confirmAlert}
    </>
  );
};
