import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  CommentPutRes,
} from '../../../../shared/types/client';
import { ClientGalleryFile, GalleryIdea } from '../../../../shared/types/commonGallery';
import { ClientGalleryGet } from '../../../../shared/types/clientGallery';
import { FileBySectionPut } from '../../../../shared/types';

export type SwiperFiles = {
  files: (ClientGalleryFile | GalleryIdea)[];
  currentFile: ClientGalleryFile | GalleryIdea | null;
};

type GalleryState = {
  gallery: ClientGalleryGet | null;
};

const initialState: GalleryState = { gallery: null };

const gallerySlice = createSlice({
  name: 'gallery',
  initialState,
  reducers: {
    setGallery(state, action: PayloadAction<ClientGalleryGet>) {
      state.gallery = action.payload;
    },
    likeHandle(state, action: PayloadAction<FileBySectionPut[]>) {
      state.gallery?.sections.forEach((section) => {
        section.files.forEach((file) => {
          if (action.payload.find((item) => item.file.includes(file.id))) {
            file.isFavourited = !file.isFavourited;
          }
        });
        section.ideas.forEach((idea) => {
          if (action.payload.find((item) => item.file.includes(idea.id))) {
            idea.isFavourited = !idea.isFavourited;
          }
        });
      });
    },
    cartHandle(state, action: PayloadAction<{
      cartFiles: FileBySectionPut[];
      limitedFreeCartFiles: Array<FileBySectionPut>;
      type: 'delete' | 'add';
  }>) {
      state.gallery?.sections.forEach((section) => {
        const current = action.payload.limitedFreeCartFiles.filter(
          (item) => item.sectionId === section.id,
        );
        if (action.payload.type === 'add') {
          section.freeFilesRemaining! -= current?.length || 0;
        } else {
          section.freeFilesRemaining! += current?.length || 0;
        }
      });
      const allFiles = [...action.payload.cartFiles, ...action.payload.limitedFreeCartFiles];
      state.gallery?.sections.forEach((section) => {
        section.files.forEach((file) => {
          if (allFiles.find((item) => item.file === file.id)) {
            file.isInCart = !file.isInCart;
          }
        });
        section.ideas.forEach((idea) => {
          if (allFiles.find((item) => item.file === idea.id)) {
            idea.isInCart = !idea.isInCart;
          }
        });
      });
    },
    addCommentCreatorHandle(state, action: PayloadAction<CommentPutRes>) {
      state.gallery?.sections.forEach((section) => {
        section.files.forEach((file) => {
          if (action.payload.fileId === file.id) {
            file.comments = [
              ...(file.comments || []),
              {
                id: action.payload.messageId,
                text: action.payload.text,
                date: action.payload.date,
                creatorMessage: true,
                // email: action.payload.email,
              },
            ];
          }
        });
        section.ideas.forEach((idea) => {
          if (action.payload.fileId === idea.id) {
            idea.comments = [
              ...(idea.comments || []),
              {
                id: action.payload.messageId,
                text: action.payload.text,
                date: action.payload.date,
                creatorMessage: true,
                // email: action.payload.email,
              },
            ];
          }
        });
      });
    },
    deleteCommentHandle(
      state,
      action: PayloadAction<{ fileId: string; commentId: string }>,
    ) {
      state.gallery?.sections.forEach((section) => {
        section.files.forEach((file) => {
          if (action.payload.fileId === file.id) {
            file.comments = file.comments?.filter(
              (item) => item.id !== action.payload.commentId,
            );
          }
        });
        section.ideas.forEach((idea) => {
          if (action.payload.fileId === idea.id) {
            idea.comments = idea.comments?.filter(
              (item) => item.id !== action.payload.commentId,
            );
          }
        });
      });
    },
  },
});

export const {
  setGallery,
  likeHandle,
  cartHandle,
  deleteCommentHandle,
  addCommentCreatorHandle,
} = gallerySlice.actions;
export default gallerySlice.reducer;
