import * as Yup from 'yup';
import {
  maxNumber,
  messageMaxLength,
  titleMaxLength,
  maxPriceLength,
  galleryPasswordMaxLength,
} from '../../../constants/validationValues';
import { errorMessages } from '../../utils/errors/errorsMessages';
import { GalleryIdea } from '../../types/commonGallery';

export const getNewGallerySchema = (step: number) => {
  if (step === 1) {
    return Yup.object().shape({
      name: Yup.string().required(errorMessages.requiredField),
      coverFile: Yup.object().shape({
        original: Yup.object().shape({
          id: Yup.string().required(errorMessages.requiredField),
        }),
      }),
      date: Yup.mixed().nullable(false).required(errorMessages.requiredField),
      message: Yup.string().max(messageMaxLength, errorMessages.tooLong),
      galleryDesign: Yup.number()
        .nullable(false)
        .min(1, errorMessages.requiredField)
        .required(errorMessages.requiredField),
    });
  }
  if (step === 2) {
    return Yup.object().shape({
      sections: Yup.array().of(
        Yup.object().shape({
          title: Yup.string()
            .required(errorMessages.requiredField)
            .max(titleMaxLength, errorMessages.tooLong),
          files: Yup.mixed().when('ideas', {
            is: (val: GalleryIdea[]) => val.length === 0,
            then: Yup.array().min(1, errorMessages.minNumberFiles),
          }),
          ideas: Yup.array().of(
            Yup.object().shape({
              name: Yup.string()
                .required(errorMessages.requiredField)
                .max(titleMaxLength, errorMessages.tooLong),
              description: Yup.string().max(messageMaxLength, 'Too long'),
              price: Yup.string()
                .required(errorMessages.requiredField)
                .max(maxPriceLength, errorMessages.tooLong)
                .nullable(false),
              cover: Yup.mixed()
                .nullable(false)
                .required(errorMessages.requiredField),
            }),
          ),
        }),
      ),
    });
  }
  if (step === 3) {
    return Yup.object().shape({
      sections: Yup.array().of(
        Yup.object().shape({
          freeFileLimit: Yup.number()
            // .moreThan(minNumber, errorMessages.minNumber)
            .lessThan(maxNumber, errorMessages.tooLong)
            .nullable(true)
            .typeError(errorMessages.typeNumber),
        }),
      ),
    });
  }
  if (step === 4) {
    return Yup.object().shape({
      whitelistEmails: Yup.mixed().when('hasWhitelistEmails', {
        is: true,
        then: Yup.array().of(Yup.string()
          .email(errorMessages.invalidEmail))
          .min(1, errorMessages.requiredField),
      }).required(errorMessages.requiredField),
      whenRemind: Yup.mixed().when('hasReminder', {
        is: true,
        then: Yup.array().of(Yup.string()).min(1, errorMessages.requiredField),
      }),
      password: Yup.string()
        .max(galleryPasswordMaxLength, errorMessages.tooLong),
    });
  }
  return null;
};
