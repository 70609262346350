import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { fetchGetOrdersByCreatorId, fetchGetOrdersByGalleryId } from './ordersThunk';
import {
  GetOrderByCreatorIdResponse, GetOrderByGalleryIdDto, GetOrderByGalleryIdResponse, Order,
} from '../../types/sales';

type InitialState = {
  loading: boolean;
  error: string | null;
  ordersByCreatorId: Order[];
  ordersByGalleryId: GetOrderByGalleryIdDto | null;
  total: number;
};

export const initialState: InitialState = {
  ordersByCreatorId: [],
  ordersByGalleryId: null,
  loading: true,
  error: null,
  total: 0,
};

const ordersSlice = createSlice({
  name: 'ordersSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchGetOrdersByGalleryId.fulfilled,
      (state, action: PayloadAction<GetOrderByGalleryIdResponse>) => {
        state.error = null;
        state.loading = false;
        state.ordersByGalleryId = action.payload.orders;
        state.total = action.payload.count;
      },
    );
    builder.addCase(
      fetchGetOrdersByGalleryId.rejected,
      (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loading = false;
      },
    );
    builder.addCase(fetchGetOrdersByGalleryId.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchGetOrdersByCreatorId.fulfilled,
      (state, action: PayloadAction<GetOrderByCreatorIdResponse>) => {
        state.error = null;
        state.loading = false;
        state.ordersByCreatorId = action.payload.orders;
        state.total = action.payload.orders?.length ? action.payload.count : 0;
      },
    );
    builder.addCase(
      fetchGetOrdersByCreatorId.rejected,
      (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loading = false;
      },
    );
    builder.addCase(fetchGetOrdersByCreatorId.pending, (state) => {
      state.loading = true;
    });
  },
});

export default ordersSlice.reducer;
