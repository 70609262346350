import React, { useMemo } from 'react';
import { ChangeColorButton } from '../../../../../../../../shared/components/GradientCoverWithActions/styled';
import { InstantDownload, HeartIcon } from '../../../../../../../../shared/icons';
import { BaseColors, ClientGalleryFile } from '../../../../../../../../shared/types';
import { getBaseColor } from '../../../../../../../../shared/utils/style/getBaseColor';
import { useLike } from '../../../../../../hooks/buttons/useLike';
import { LikeAndInstDownloadableWrapper } from './styled';
import { useTypedSelectorClient } from '../../../../../../../../shared/hooks/useTypedSelector';
import { getAllFilesFromCart } from '../../../../../../../Client/utils/getAllFilesFromCart';
import { selectClientFavourites } from '../../../../../../../Client/redux/interactions/interactionsClientSelectors';

type InstantlyDownloadableOrLikeProps = {
  instantlyDownloadable: boolean;
  disabledAll: boolean;
  fileId: string;
  iconsColor?: BaseColors;
  isFileLiked: boolean;
  file: ClientGalleryFile;
};

export const InstantlyDownloadableOrLike: React.FC<
  InstantlyDownloadableOrLikeProps
> = ({
  instantlyDownloadable, disabledAll, fileId, iconsColor, isFileLiked, file,
}) => {
  const { isLiked, handleLike } = useLike(isFileLiked);
  const { files: favFiles } = useTypedSelectorClient(
    (state) => selectClientFavourites(state.interactionsClient?.interaction?.favourites),
  );

  const isLikedLocal = useMemo(
    () => !!favFiles?.find((item) => item.id === fileId),
    [favFiles, fileId],
  );

  return (
    <LikeAndInstDownloadableWrapper>
      <span id="inst-downloadable">
        {instantlyDownloadable && <InstantDownload />}
      </span>
      <LikeAndInstDownloadableWrapper>
        <ChangeColorButton
          isClicked={isLiked || isLikedLocal}
          disabled={!!disabledAll}
          type="button"
          onClick={() => handleLike(file)}
          id="client-like-button-step"
          baseColor={iconsColor ? getBaseColor(iconsColor) : ''}
        >
          <HeartIcon />
        </ChangeColorButton>
      </LikeAndInstDownloadableWrapper>
    </LikeAndInstDownloadableWrapper>
  );
};
