import { Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  SimpleBlackText,
} from '../../../../../../../../../shared/styles';
import { RegisteredEmailRow } from './RegisteredEmailRow';
import {
  RegisteredEmailTitle,
  ScrolledWrapperRegisteredEmails,
  TitleMediumBlackTextMgB,
} from './styled';
import { RegisteredEmail, ViewCreator } from '../../../../../../../../../shared/types/commonGallery';
import { ErrorMessage } from '../../../../../../../../../shared/components/styled';
import { useTypedSelectorCreator } from '../../../../../../../../../shared/hooks/useTypedSelector';

type RegisteredEmailsProps = {
  registeredEmails: ViewCreator[];
  galleryId: string;
};

export const RegisteredEmailsTab: React.FC<RegisteredEmailsProps> = ({
  registeredEmails,
  galleryId,
}) => {
  const { t } = useTranslation('gallery');
  const error = useTypedSelectorCreator((state) => state.interactions.error);

  if (!registeredEmails?.length) { return <SimpleBlackText>{t('noEmailRegistrations')}</SimpleBlackText>; }
  const sortedArr = [...registeredEmails];

  sortedArr.sort(
    (item1: RegisteredEmail, item2: RegisteredEmail) => (new Date(item2.lastVisitDate || '') as any)
      - (new Date(item1.lastVisitDate || '') as any),
  );

  return (
    <>
      <RegisteredEmailTitle>
        <SimpleBlackText>{t('registeredEmails.title')}</SimpleBlackText>
        {error && <ErrorMessage style={{ fontSize: '14px' }}>{error}</ErrorMessage>}
        {/* hide for now */}
        {/* <RegisteredEmailExportButton>
          <DownloadIcon />
          <MediumGreyText>{t('registeredEmails.export')}</MediumGreyText>
        </RegisteredEmailExportButton> */}
      </RegisteredEmailTitle>
      <Grid container item sm={12}>
        <Grid item sm={7}>
          <TitleMediumBlackTextMgB fontWeight={500}>
            {t('registeredEmails.visitorEmail')}
          </TitleMediumBlackTextMgB>
        </Grid>
        <Grid item sm={5}>
          <TitleMediumBlackTextMgB fontWeight={500}>
            {t('registeredEmails.lastVisit')}
          </TitleMediumBlackTextMgB>
        </Grid>
      </Grid>
      <ScrolledWrapperRegisteredEmails>
        <Grid container spacing={0}>
          {sortedArr.map((item) => (
            <RegisteredEmailRow
              key={item.email}
              row={item}
              galleryId={galleryId}
            />
          ))}
        </Grid>
      </ScrolledWrapperRegisteredEmails>
    </>
  );
};
