import { createSelector } from '@reduxjs/toolkit';
import { FileBySection, ClientCart, ClientFavourites } from '../../../../shared/types';
import { getAllFilesFromCart } from '../../utils/getAllFilesFromCart';

const selectFavourites = (favs: FileBySection[]) => favs;
const selectCart = (cart: FileBySection[]) => cart;
const selectLimitedFreeCartFiles = (files: FileBySection[]) => files;

export const selectClientFavourites = createSelector<(
typeof selectFavourites)[], ClientFavourites>(
  selectFavourites,
  (favs) => {
    if (!favs?.length) {
      return {
        files: [],
        totalFiles: 0,
      };
    }
    const allFavs = getAllFilesFromCart(favs);
    const totalFiles = getAllFilesFromCart(favs)?.length || 0;
    return {
      files: allFavs,
      totalFiles,
    };
  },
  );
export const selectClientLimitedFreeCartFiles = createSelector<(
    typeof selectLimitedFreeCartFiles)[],
    ClientCart
  >(
  selectLimitedFreeCartFiles,
  (items: FileBySection[]) => {
    if (!items?.length) {
      return {
        sections: [],
        totalFiles: 0,
      };
    }
    const resItems = items.map((item) => ({ sectionId: item.sectionId, file: { ...item.file, price: '0' } }));
    const totalFiles = getAllFilesFromCart(items)?.length || 0;
    return {
      sections: resItems,
      totalFiles,
    };
  },
  );
export const selectClientCart = createSelector<(
  typeof selectCart)[],
  ClientCart
  >(
  selectCart,
  (cartItems) => {
    if (!cartItems?.length) {
      return {
        sections: [],
        totalFiles: 0,
      };
    }
    const totalFiles = getAllFilesFromCart(cartItems)?.length || 0;
    return {
      sections: cartItems,
      totalFiles,
    };
  },
  );
