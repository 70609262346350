import React from 'react';
import { useTranslation } from 'react-i18next';
import { SimpleTooltip } from '../../../../../../../../../shared/components/HelpTip/SimpleTooltip';
import {
  DownloadIcon,
  HeartIcon,
} from '../../../../../../../../../shared/icons';
import {
  LikesOrDownloadsFileWrapper,
  LikesOrDownloadsIconWrapper,
  LikesOrDownloadsWrapper,
} from './styled';
import { SimpleBlackText } from '../../../../../../../../../shared/styles';
import { getFileInContainer } from '../../../../../../../../../shared/utils/createGallery';
import { isIdea } from '../../../../../../../../../shared/utils';
import {
  CreatorGalleryFile,
  GalleryIdea,
} from '../../../../../../../../../shared/types/commonGallery';
import { FavouritesCartResItem } from '../../../../../../../../../shared/types';
import { getFileUrls } from '../../../../../../../../../shared/utils/gallery/setFileUrls';

type LikesOrDownloadsProps = {
  likesOrDownloads: FavouritesCartResItem[];
  type: 'like' | 'download';
};

export const LikesOrDownloadsTab: React.FC<LikesOrDownloadsProps> = ({
  likesOrDownloads,
  type,
}) => {
  const { t } = useTranslation('gallery');

  if (!likesOrDownloads?.length) {
    if (type === 'download') {
      return <SimpleBlackText>{t('noDownloads')}</SimpleBlackText>;
    }
    return <SimpleBlackText>{t('noLikes')}</SimpleBlackText>;
  }

  return (
    <LikesOrDownloadsWrapper>
      {likesOrDownloads.map((item) => {
        const fileWithUrls = getFileUrls(
          isIdea(item)
            ? ((item as GalleryIdea).cover as CreatorGalleryFile)
            : (item as CreatorGalleryFile),
        );
        return (
          <LikesOrDownloadsFileWrapper key={item.id}>
            {getFileInContainer({
              file: fileWithUrls,
            })}
            <LikesOrDownloadsIconWrapper style={{ background: 'transparent' }}>
              {type === 'download' ? <DownloadIcon /> : <HeartIcon />}
              <SimpleTooltip text={item.email || 'Anonym'} relativeWidth={20} />
            </LikesOrDownloadsIconWrapper>
          </LikesOrDownloadsFileWrapper>
        );
      })}
    </LikesOrDownloadsWrapper>
  );
};
