import { ClientGalleryGet } from '../../../shared/types/clientGallery';
import { CreatorGalleryGet } from '../../../shared/types/creatorGallery';

export const getSectionId = (
  gallery: ClientGalleryGet | CreatorGalleryGet | null,
  fileId: string,
): string => {
  if (!gallery) {
    return '';
  }
  const res = gallery.sections.find((section) =>
    // eslint-disable-next-line implicit-arrow-linebreak
    [...section.files, ...section.ideas].find((file) => file.id === fileId));
  return res?.id || '';
};
