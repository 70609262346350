import { BrokenImage } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import React from 'react';
import * as variables from '../../../constants/styles';

export const BrokenFile: React.FC<{ url: string, error: boolean }> = ({ url, error }) => {
  if (((url.includes('/undefined') || url.includes('media//')) && url) || error) {
    return (
      <Box sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
      >
        <BrokenImage fontSize="large" />
        <Typography sx={{ color: variables.textGrey }}>Not found</Typography>
      </Box>
    );
  }
  return null;
};
