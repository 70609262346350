import {
  Variations,
  FileFormats,
} from '../../../modules/Creator/types/gallery';
import { ClientGalleryGetResponse } from '../../types/clientGallery';
import {
  ClientGalleryFile,
  ClientOrCreatorGalleryFile,
  CreatorGalleryFile,
  GalleryFileBase,
  GalleryFileBaseWithUrls,
} from '../../types/commonGallery';
import { CreatorGalleryGetResponse } from '../../types/creatorGallery';
import { isVideo } from '../commonFunctions';

export const getImageUrls = (file?: ClientGalleryFile | null) => {
  if (!file) {
    return {
      urlWatermarked: '',
      url: '',
    };
  }
  const urlWatermarked = `${process.env.REACT_APP_BASE_MEDIA_URL}/${file.id}/${Variations.Watermarked}.${FileFormats.Photo}`;
  const url = `${process.env.REACT_APP_BASE_MEDIA_URL}/${file.id}/${Variations.Full}.${FileFormats.Photo}`;

  return {
    urlWatermarked,
    url,
  };
};

export const getVideoUrls = (
  file?: ClientGalleryFile | CreatorGalleryFile | null,
) => {
  if (!file) {
    return {
      urlWatermarked: '',
      url: '',
      urlFullSize: '',
    };
  }
  let hasVideoThumbnail = false;
  let videoThumbnailFile: GalleryFileBaseWithUrls | undefined;

  if (file.videoThumbnailFile) {
    hasVideoThumbnail = true;
    videoThumbnailFile = {
      url: `${process.env.REACT_APP_BASE_MEDIA_URL}/${
        (file.urlVideoThumbnailFile as any)?.id || file.videoThumbnailFile
      }/${Variations.Full}.${FileFormats.Photo}`,
      id: `cover-${file.id}`,
      name: `cover-${file.id}`,
      type: 'image',
    };
  }
  const url = `${process.env.REACT_APP_BASE_MEDIA_URL}/${file.id}/${Variations.Preview}.${FileFormats.Video}`;
  const urlFullSize = `${process.env.REACT_APP_BASE_MEDIA_URL}/${file.id}/${Variations.Full}.${FileFormats.Video}`;
  const urlWatermarked = `${process.env.REACT_APP_BASE_MEDIA_URL}/${file.id}/${Variations.Watermarked}.${FileFormats.Video}`;

  return {
    videoThumbnailFile,
    hasVideoThumbnail,
    urlWatermarked,
    url,
    urlFullSize,
  };
};

export const attachGalleryCoverUrl = (cover?: any) => {
  if (cover?.id) {
    cover = {
      desktop: {
        ...(cover as any),
        url: `${process.env.REACT_APP_BASE_MEDIA_URL}/${cover as string}/${
          Variations.Full
        }.${FileFormats.Photo}`,
        type: 'image',
      },
      original: {
        ...(cover as any),
        url: `${process.env.REACT_APP_BASE_MEDIA_URL}/${cover as string}/${
          Variations.Full
        }.${FileFormats.Photo}`,
        type: 'image',
      },
      tablet: {
        ...(cover as any),
        type: 'image',
      },
      mobile: {
        ...(cover as any),
        type: 'image',
      },
    };
  } else {
    const id = cover;
    cover = {
      desktop: {
        id: id as string,
        url: `${process.env.REACT_APP_BASE_MEDIA_URL}/${id as string}/${
          Variations.Full
        }.${FileFormats.Photo}`,
        type: 'image',
      },
      original: {
        id: id as string,
        url: `${process.env.REACT_APP_BASE_MEDIA_URL}/${id as string}/${
          Variations.Full
        }.${FileFormats.Photo}`,
        type: 'image',
      },
      tablet: {
        id: id as string,
        type: 'image',
      },
      mobile: {
        id: id as string,
        type: 'image',
      },
    };
  }
  return cover;
};

export const getFileUrls = <FileType extends GalleryFileBase>(
  file: FileType,
  isCover?: boolean,
): ClientOrCreatorGalleryFile => {
  if (isVideo(file)) {
    const videoObj = getVideoUrls(file);
    return { ...file, ...videoObj };
  }
  if (isCover) {
    return attachGalleryCoverUrl(file);
  }
  const imageObj = getImageUrls(file);
  return { ...file, ...imageObj };
};

export const attachGalleryFilesUrls = <T>(
  gallery: ClientGalleryGetResponse | any | CreatorGalleryGetResponse,
): T => {
  const cover = attachGalleryCoverUrl(gallery.coverFile);
  gallery.coverFile = cover;
  gallery.sections.forEach((section: any) => {
    section.files.forEach((file: any) => {
      const imageUrls = getImageUrls(file);
      const videoUrls = getVideoUrls(file);
      if (isVideo(file)) {
        file.hasVideoThumbnail = videoUrls.hasVideoThumbnail;
        file.videoThumbnailFile = videoUrls.videoThumbnailFile;
        file.url = videoUrls.url;
        file.urlFullSize = videoUrls.urlFullSize;
        file.urlWatermarked = videoUrls.urlWatermarked;
      } else {
        file.url = imageUrls.url;
        file.urlWatermarked = imageUrls.urlWatermarked;
      }
    });
  });
  return gallery as T;
};

export const attachFilesUrls = <FileType extends GalleryFileBase>(
  files: FileType[],
): FileType[] => {
  files.forEach((section: any) => {
    section.files.forEach((file: any) => {
      const imageUrls = getImageUrls(file);
      const videoUrls = getVideoUrls(file);
      if (isVideo(file)) {
        file.hasVideoThumbnail = videoUrls.hasVideoThumbnail;
        file.videoThumbnailFile = videoUrls.videoThumbnailFile;
        file.url = videoUrls.url;
        file.urlFullSize = videoUrls.urlFullSize;
        file.urlWatermarked = videoUrls.urlWatermarked;
      } else {
        file.url = imageUrls.url;
        file.urlWatermarked = imageUrls.urlWatermarked;
      }
    });
  });
  return files;
};
