import { Grid } from '@material-ui/core';
import React from 'react';
import { useAuth } from '@clerk/clerk-react';
import { useCreatorAppDispatch, useTypedSelectorCreator } from '../../../../../../../../../shared/hooks/useTypedSelector';
import { getFormattedDateOrDateName } from '../../../../../../../../../shared/utils';
import { MediumBlackTextMgTB } from './styled';
import { fetchDeleteCreatorViews } from '../../../../../../../redux/gallery/interactions/interactionsCreatorThunk';
import { ViewCreator } from '../../../../../../../../../shared/types/commonGallery';

type RegisteredEmailRowProps = {
  row: ViewCreator;
  galleryId: string;
};

export const RegisteredEmailRow: React.FC<RegisteredEmailRowProps> = ({
  row,
  galleryId,
}) => {
  const dateFormat = useTypedSelectorCreator((state) => state.creator?.currentUser?.dateFormat) || 'mm/dd/yyyy';
  const timezone = useTypedSelectorCreator((state) => state.creator?.currentUser?.timezone);
  const formattedDate = getFormattedDateOrDateName(row.lastVisitDate, dateFormat, timezone, true);
  const loading = useTypedSelectorCreator((state) => state.interactions.loadingDelete);
  const { getToken } = useAuth();
  const dispatch = useCreatorAppDispatch();

  const handleDeleteView = () => {
    dispatch(fetchDeleteCreatorViews({
      galleryId, email: row.email, getToken, interactionId: row.interactionId, view: row,
    }));
  };

  return (
    <Grid container item sm={12}>
      <Grid item sm={7}>
        <MediumBlackTextMgTB>{row?.email || 'unknown'}</MediumBlackTextMgTB>
      </Grid>
      <Grid item sm={5}>
        <MediumBlackTextMgTB>
          {formattedDate || 'not indicated'}
        </MediumBlackTextMgTB>
      </Grid>
    </Grid>
  );
};
