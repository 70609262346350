import React, { useEffect } from 'react';
import { CommentAreaWrapper, CommentErrorWrapper } from './styled';
import { CommentItem } from './CommentItem';
import { CommentItemWithTextarea } from './CommentItemWithTextarea';
import { GalleryComment } from '../../../../shared/types';
import {
  useTypedSelectorClient,
  useTypedSelectorCreator,
  useTypedSelectorGallery,
} from '../../../../shared/hooks/useTypedSelector';
import { getFullName } from '../../../../shared/utils';
import { ErrorMessage } from '../../../../shared/components/styled';

type CommentAreaProps = {
  comments: GalleryComment[];
  dateFormat: string;
  addComment: (comment: string, resetForm: () => void) => void;
  deleteComment: (commentId: string) => void;
  userProfileUrl?: string;
};

export const CommentArea: React.FC<CommentAreaProps> = ({
  comments,
  dateFormat,
  addComment,
  deleteComment,
  userProfileUrl,
}) => {
  const clientEmail = useTypedSelectorClient(
    (state) => state?.signIn?.clientEmail,
  );
  const creator = useTypedSelectorCreator(
    (state) => state?.creator?.currentUser,
  );
  const creatorBusinessName = useTypedSelectorGallery(
    (state) => state.gallery.gallery?.creatorBusinessName,
  );
  const errorCreator = useTypedSelectorCreator(
    (state) => state?.interactions?.error,
  );
  const errorClient = useTypedSelectorClient((state) => state?.comments?.error);

  // const userName = creator ? getFullName(creator?.firstName, creator?.lastName) : clientEmail;
  const userName = creator
    ? creatorBusinessName || creator?.businessName
    : clientEmail;

  useEffect(() => {
    const modalWindow = document.getElementsByClassName('MuiPaper-root');
    modalWindow[0]?.scrollTo({
      top: modalWindow[0].scrollHeight,
      behavior: 'smooth',
    });
  }, []);

  return (
    <>
      {errorCreator || errorClient ? (
        <CommentErrorWrapper>
          <ErrorMessage style={{ fontSize: '14px' }}>
            {errorCreator || errorClient}
          </ErrorMessage>
        </CommentErrorWrapper>
      ) : null}
      <CommentAreaWrapper id="scrolled-wrap">
        {comments?.map((item) => (
          <CommentItem
            clientEmail={clientEmail}
            creatorEmail={creator?.email}
            creatorBusinessName={
              creatorBusinessName || creator?.businessName || ''
            }
            deleteComment={deleteComment}
            key={item.id}
            comment={item}
            dateFormat={dateFormat}
            timezone={creator?.timezone}
          />
        ))}
        <CommentItemWithTextarea
          currentUserName={userName}
          addComment={addComment}
          urlUserAvatar={userProfileUrl || ''}
        />
      </CommentAreaWrapper>
    </>
  );
};
