import React from 'react';
import { useTranslation } from 'react-i18next';
import { TabPanel } from './TabPanel';
import { GalleryDetailsTabContent, GalleryDetailsWrapper, TabsWrapper } from './styled';
import { CommentsTab } from './CommentsTab/CommentsTab';
import { UnderlineTabs } from '../../../../../../../../shared/components/Tabs/UnderlineTabs/UnderlineTabs';
import { BaseUnderlineTab } from '../../../../../../../../shared/components/Tabs/UnderlineTabs/BaseUnderlineTab';
import { RegisteredEmailsTab } from './RegisteredEmailsTab/RegisteredEmailsTab';
import { LikesOrDownloadsTab } from './LikesOrDownloadsTab/LikesOrDownloadsTab';
import { GalleryIdea, ViewCreator } from '../../../../../../../../shared/types/commonGallery';
import { OrdersTab } from './OrdersTab/OrdersTab';
import { Order } from '../../../../../../types/sales';
import { FavouritesCartResItem } from '../../../../../../../../shared/types';

type GalleryTabsDetailsProps = {
  galleryId: string;
  likes: FavouritesCartResItem[];
  downloads: FavouritesCartResItem[];
  ideas: GalleryIdea[];
  orders: Order[];
  registeredEmails: ViewCreator[];
  galleryName: string;
};

const useGetTabsGalleryDetails = () => {
  const { t } = useTranslation('gallery');
  const tabs = [
    { label: t('emailRegistrations'), id: 'emailRegistrations' },
    { label: t('commentsLabel'), id: 'comments' },
    { label: t('likesLabel'), id: 'likes' },
    { label: t('downloadsLabel'), id: 'downloads' },
    { label: t('orders'), id: 'orders' },
    // { label: t('ideasLabel'), id: 'ideas' },
  ];
  return tabs;
};

export const GalleryTabsDetails: React.FC<GalleryTabsDetailsProps> = ({
  galleryId,
  likes,
  downloads,
  ideas,
  orders,
  registeredEmails,
  galleryName,
}) => {
  const [value, setValue] = React.useState(0);
  const tabs = useGetTabsGalleryDetails();

  return (
    <GalleryDetailsWrapper>
      <TabsWrapper>
        <UnderlineTabs
          value={value}
          setValue={setValue}
          tabsElement={
            tabs.map((item) => (
              <BaseUnderlineTab
                tabsnumber={tabs.length}
                key={item.id}
                label={item.label}
                id={item.id}
                sx={{
                  whiteSpace: 'pre',
                }}
              />
            ))
          }
        />
      </TabsWrapper>
      <TabPanel noOverflowY value={value} index={0}>
        <GalleryDetailsTabContent>
          <RegisteredEmailsTab registeredEmails={registeredEmails} galleryId={galleryId} />
        </GalleryDetailsTabContent>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <CommentsTab galleryId={galleryId} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <GalleryDetailsTabContent>
          <LikesOrDownloadsTab type="like" likesOrDownloads={likes} />
        </GalleryDetailsTabContent>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <GalleryDetailsTabContent>
          <LikesOrDownloadsTab type="download" likesOrDownloads={downloads} />
        </GalleryDetailsTabContent>
      </TabPanel>
      <TabPanel noOverflowY value={value} index={4} style={{ paddingTop: '0px', marginTop: '28px' }}>
        <GalleryDetailsTabContent>
          <OrdersTab galleryId={galleryId} orders={orders} galleryName={galleryName} />
        </GalleryDetailsTabContent>
      </TabPanel>
      {/* hide for now */}
      {/* <TabPanel value={value} index={4}>
        <CommentsOrIdeasListWrapper>
          <IdeasTab ideas={ideas} />
        </CommentsOrIdeasListWrapper>
      </TabPanel> */}
    </GalleryDetailsWrapper>
  );
};
