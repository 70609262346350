import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { CommonPriceInputReadOnly } from '../../../../../shared/components/CommonPriceInputReadOnly/CommonPriceInputReadOnly';
import { PinkLoader } from '../../../../../shared/components/Loaders/PinkLoader/PinkLoader';
import { useTypedSelectorClient } from '../../../../../shared/hooks/useTypedSelector';
import { MediumBlackText, SimpleBlackText } from '../../../../../shared/styles';
import { HrLine } from '../../../../../shared/styles/Elements';
import { ClientContentErrorMessage } from '../../ClientShared/ClientPageContent/styled';
import { CartSummarySection } from '../CartSummary/CartSummarySection';
import {
  CartPopupBlurBackground,
  CartPopupContainer, CartPopupSectionsWrapper, CartPopupSubtotalWrapper, CartPopupWrapper,
} from './styled';
import { CartInteractionSection, CurrencyTypes } from '../../../../../shared/types';
import { getAllFilesFromCart } from '../../../utils/getAllFilesFromCart';
import { selectClientCart, selectClientLimitedFreeCartFiles } from '../../../redux/interactions/interactionsClientSelectors';

type CartPopupBlurProps = {
  isLoading: boolean;
  error: string | null;
};

const CartPopupBlur: React.FC<CartPopupBlurProps> = ({ isLoading, error }) => {
  if (!isLoading && !error) {
    return null;
  }
  return (
    <CartPopupBlurBackground>
      {isLoading ? <PinkLoader /> : null}
      {error ? <ClientContentErrorMessage>{error}</ClientContentErrorMessage> : null}
    </CartPopupBlurBackground>
  );
};
export const CartPopupWindow: React.FC = () => {
  const { t } = useTranslation(['gallery', 'sales']);

  const {
    sections, totalFiles,
  } = useTypedSelectorClient(
    (state) => selectClientCart(state.interactionsClient.interaction.cartFiles),
  );
  const {
    sections: sectionsFree, totalFiles: totalFreeFiles,
  } = useTypedSelectorClient(
    (state) => selectClientLimitedFreeCartFiles(
      state.interactionsClient.interaction.limitedFreeCartFiles,
    ),
  );

  const isLoading = useTypedSelectorClient((state) => state.interactionsClient?.loadingGet);
  const error = useTypedSelectorClient((state) => state.interactionsClient?.error);
  const gallery = useTypedSelectorClient((state) => state.signIn.gallery);

  const { cartSections, totalSum } = useMemo(() => {
    let totalSumLocal = 0;
    const cartSectionsLocal: CartInteractionSection[] = gallery?.sections.map((item) => ({
      title: item.title,
      sectionId: item.id,
      filesNumber: 0,
      totalSum: 0,
      files: [],
    })) || [];

    const files = getAllFilesFromCart([...sections, ...sectionsFree]);
    cartSectionsLocal.forEach((item) => {
      files.forEach((file) => {
        if (file.sectionId === item.sectionId) {
          item.filesNumber += 1;
          item.totalSum += Number(file.price || 0);
          item.files.push(file);
        }
      });
      totalSumLocal += item.totalSum;
    });
    return { cartSections: cartSectionsLocal, totalSum: totalSumLocal };
  }, [gallery, sections, sectionsFree]);

  return (
    <CartPopupContainer>
      <CartPopupWrapper>
        <SimpleBlackText fontWeight={600}>
          {totalFiles + totalFreeFiles
            ? (
              <Trans t={t} i18nKey="pageTitle">
                {{ title: t('cart.title') }}
                {' '}
                {{ files: totalFiles + totalFreeFiles }}
              </Trans>
            )
            : t('cart.title')}
        </SimpleBlackText>
        <div style={{ width: '100%' }}>
          <CartPopupSectionsWrapper>
            {cartSections?.map((item) => (
              <CartSummarySection section={item} key={item?.sectionId} />
            ))}
          </CartPopupSectionsWrapper>
          <HrLine />
          <CartPopupSubtotalWrapper>
            <MediumBlackText fontWeight={500}>{t('sales:subTotal')}</MediumBlackText>
            <MediumBlackText fontWeight={500}>
              <CommonPriceInputReadOnly currencyLetters="USD" currenyType={CurrencyTypes.USD} value={totalSum || 0} />
            </MediumBlackText>
          </CartPopupSubtotalWrapper>
        </div>
        <CartPopupBlur isLoading={isLoading} error={error} />
      </CartPopupWrapper>
    </CartPopupContainer>
  );
};
