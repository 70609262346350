import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ErrorType } from '../../../../../shared/types';
import {
  fetchCreateCreatorGallery,
  fetchUpdateCreatorGallery,
  deleteGallerySection,
  deleteGalleryFile,
  fetchSaveCreatorGallery,
  fetchGetCreatorGallery,
} from './singleGalleryCreatorThunk';
import { CreatorGalleryGet } from '../../../../../shared/types/creatorGallery';
import { fetchDeleteCreatorGallery } from '../allGaleries/galleryCreatorThunk';

type initialState = {
  loadingGet: boolean;
  isSuccess: boolean;
  error: ErrorType;
  loadingUpdate: boolean;
  errorUpdate: string;
  isSuccessUpdate: boolean;
  loadingSave: boolean;
  errorSave: string;
  isSuccessSave: boolean;
  loadingDelete: boolean;
  errorDelete: string;
  isSuccessDelete: boolean;
  gallery?: CreatorGalleryGet;
};

export const initialState: initialState = {
  loadingGet: false,
  loadingDelete: false,
  errorDelete: '',
  isSuccessDelete: false,
  error: null,
  loadingUpdate: false,
  errorUpdate: '',
  isSuccess: false,
  isSuccessUpdate: false,
  loadingSave: false,
  errorSave: '',
  isSuccessSave: false,
  gallery: undefined,
};

const singleGalleryCreatorSlice = createSlice({
  name: 'singleGalleryCreatorSlice',
  initialState,
  reducers: {
    setSuccessUpdateFalse(state) {
      state.isSuccessUpdate = false;
    },
    setSuccessSaveFalse(state) {
      state.isSuccessSave = false;
    },
    setSuccessDeleteFalse(state) {
      state.isSuccessDelete = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchGetCreatorGallery.fulfilled,
      (state, action: PayloadAction<CreatorGalleryGet>) => {
        state.error = null;
        state.loadingGet = false;
        state.gallery = action.payload;
      },
    );
    builder.addCase(
      fetchGetCreatorGallery.rejected,
      (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loadingGet = false;
      },
    );
    builder.addCase(fetchGetCreatorGallery.pending, (state) => {
      state.loadingGet = true;
      state.error = null;
    });
    builder.addCase(
      fetchDeleteCreatorGallery.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.error = null;
        state.loadingDelete = false;
        state.isSuccessDelete = true;
        // state.activeGalleries = state.activeGalleries.filter(
        //   (item) => item.id !== action.payload,
        // );
        // state.draftGalleries = state.draftGalleries.filter(
        //   (item) => item.id !== action.payload,
        // );
      },
    );
    builder.addCase(
      fetchDeleteCreatorGallery.rejected,
      (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loadingDelete = false;
      },
    );
    builder.addCase(fetchDeleteCreatorGallery.pending, (state) => {
      state.loadingDelete = true;
      state.error = null;
    });
    builder.addCase(fetchUpdateCreatorGallery.fulfilled, (state) => {
      state.errorUpdate = '';
      state.loadingUpdate = false;
      state.isSuccessUpdate = true;
      state.error = null;
      // state.galleries = action.payload;
    });
    builder.addCase(
      fetchUpdateCreatorGallery.rejected,
      (state, action: PayloadAction<any>) => {
        state.errorUpdate = action.payload;
        state.loadingUpdate = false;
        state.isSuccessUpdate = false;
      },
    );
    builder.addCase(fetchUpdateCreatorGallery.pending, (state) => {
      state.loadingUpdate = true;
      state.isSuccessUpdate = false;
      state.errorUpdate = '';
      state.error = null;
    });
    builder.addCase(fetchSaveCreatorGallery.fulfilled, (state) => {
      state.errorSave = '';
      state.loadingSave = false;
      state.isSuccessSave = true;
      // state.galleries = action.payload;
    });
    builder.addCase(
      fetchSaveCreatorGallery.rejected,
      (state, action: PayloadAction<any>) => {
        state.errorSave = action.payload;
        state.loadingSave = false;
        state.isSuccessSave = false;
      },
    );
    builder.addCase(fetchSaveCreatorGallery.pending, (state) => {
      state.loadingSave = true;
      state.isSuccessSave = false;
      state.errorSave = '';
      state.error = null;
    });
    builder.addCase(fetchCreateCreatorGallery.fulfilled, (state) => {
      state.error = null;
      state.loadingGet = false;
      // state.galleries = action.payload;
    });
    builder.addCase(
      fetchCreateCreatorGallery.rejected,
      (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loadingGet = false;
      },
    );
    builder.addCase(fetchCreateCreatorGallery.pending, (state) => {
      state.loadingGet = true;
      state.error = null;
    });
    builder.addCase(deleteGallerySection.fulfilled, (state) => {
      state.error = null;
      // state.loadingGet = false;
      // state.galleries = action.payload;
    });
    builder.addCase(
      deleteGallerySection.rejected,
      (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        // state.loadingGet = false;
      },
    );
    builder.addCase(deleteGalleryFile.pending, () => {
      // state.loadingGet = true;
    });
    builder.addCase(deleteGalleryFile.fulfilled, (state) => {
      state.error = null;
      // state.loadingGet = false;
      // state.galleries = action.payload;
    });
    builder.addCase(
      deleteGalleryFile.rejected,
      (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        // state.loadingGet = false;
      },
    );
    builder.addCase(deleteGallerySection.pending, () => {
      // state.loadingGet = true;
    });
  },
});

export const {
  setSuccessUpdateFalse,
  setSuccessSaveFalse,
  setSuccessDeleteFalse,
} = singleGalleryCreatorSlice.actions;

export default singleGalleryCreatorSlice.reducer;
