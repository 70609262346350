import React from 'react';
import { BaseModalWindow } from '../../../../shared/components/BaseModalWindow/BaseModalWindow';
import {
  FileAndCommentsWindowWrapper,
  FileContainer,
  GalleryCommentsArea,
} from './styled';
import { CommentContainer } from './CommentContainer';
import { getFileInContainer } from '../../../../shared/utils/createGallery';
import { GalleryComment, GalleryFileBaseWithUrls } from '../../../../shared/types/commonGallery';

type FileAndCommentsWindowProps = {
  file: GalleryFileBaseWithUrls | null;
  comments: GalleryComment[];
  isOpen: boolean;
  handleClose: () => void;
  dateFormat: string;
  addComment: (comment: string, resetForm: () => void) => void;
  deleteComment: (commentId: string) => void;
  userProfileUrl?: string
};

export const FileAndCommentsWindow: React.FC<FileAndCommentsWindowProps> = ({
  file,
  comments,
  isOpen,
  handleClose,
  dateFormat,
  addComment,
  deleteComment,
  userProfileUrl,
}) => (
  <BaseModalWindow fullScreen isOpen={isOpen} handleClose={handleClose}>
    <FileAndCommentsWindowWrapper>
      <FileContainer>
        {getFileInContainer({ file })}
      </FileContainer>
      <GalleryCommentsArea>
        <CommentContainer
          addComment={addComment}
          deleteComment={deleteComment}
          comments={comments}
          handleClose={handleClose}
          dateFormat={dateFormat}
          userProfileUrl={userProfileUrl || ''}
        />
      </GalleryCommentsArea>
    </FileAndCommentsWindowWrapper>
  </BaseModalWindow>
);
