import { useEffect } from 'react';
import { useCreatorAppDispatch, useTypedSelectorCreator } from '../../../shared/hooks/useTypedSelector';
import { fetchingFiles, unsetIsSuccess } from '../redux/gallery/uploadFiles/tryAgainUploadFilesSlice';
import { fetchTryAgainFiles } from '../redux/gallery/uploadFiles/tryAgainUploadFilesThunk';
import { CreatorGalleryFile, GalleryFileBaseWithUrls } from '../../../shared/types/commonGallery';

type ReturnObj = {
  sendAndSetFiles: (
    // fieldName: string,
    // alreadyUploadedFiles: CustomFile[],
    uploadingFiles: File[]
  ) => void;
};

type SendFilesAgainReturnObj = {
  sendFilesAgain: (files: GalleryFileBaseWithUrls[]) => void;
};

export const useSendFilesAgain = (): SendFilesAgainReturnObj => {
  const dispatch = useCreatorAppDispatch();
  const isSuccess = useTypedSelectorCreator(
    (state) => state.tryAgainUploadFiles.isSuccess,
  );

  const sendFilesAgain = async (
    files: CreatorGalleryFile[],
  ) => {
    if (files?.length) {
      const ids = files?.map((item) => item.id);
      // const { formData, ids } = getFormDataAndFileObjects(filteredFiles);
      // setFieldValue(fieldName, [...alreadyUploadedFiles, ...preUploadingFiles]);
      dispatch(fetchingFiles(ids));
      dispatch(fetchTryAgainFiles(files));
    }
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(unsetIsSuccess());
    }
  }, [isSuccess]);

  return {
    sendFilesAgain,
  };
};
